import { OrganizationResponse, PMGroup } from "@/react/types";
import { instance } from "@/react/hooks/api";
import { useCurrentUser } from "@/react/context/mgmt-api/user";
import {
  createAxiosQuery,
  createAxiosMutation,
} from "@/react/hooks/react-query";
import { OrganizationSettings } from "@/react/bundles/organization/community_settings/notifications/utils";
import { Organization } from "@/react/types/Organization";
import { getErrorMessage } from "@/react/lib/axios-helpers";

export const useOrganization = createAxiosQuery<void | null, Organization>(
  "organization",
  async () => {
    const {
      data: { organization, saml_enabled },
    } = await instance().get<OrganizationResponse>("organization");

    // This is a hack to get around Phoenix serializing away saml_enabled,
    // a field which only shows up when getting the current user's organization
    return { ...organization, saml_enabled };
  }
);

interface GetOrganizationSettingsParams {
  id: number;
}

async function getOrganizationSettings({ id }: GetOrganizationSettingsParams) {
  const { data } = await instance().get<OrganizationSettings>(
    `/organizations/${id}`
  );

  return data;
}

const _useOrganizationSettingsQuery = createAxiosQuery<
  GetOrganizationSettingsParams,
  OrganizationSettings
>("organization-settings", getOrganizationSettings);

// if there is no current user, give the option to pass in an organization id
export const useOrganizationSettingsQuery = (id?: number) => {
  const user = useCurrentUser();

  return _useOrganizationSettingsQuery({
    id: id && !isNaN(id) ? id : user.organization.id,
  });
};

export interface UpdateOrganizationMutationPayload {
  id: number;
  values: Organization;
}

export const useUpdateOrganizationMutation = createAxiosMutation(
  async ({ id, values }: UpdateOrganizationMutationPayload) => {
    const { data } = await instance().patch(`/organizations/${id}`, values);
    return data;
  },
  {
    successToast: () => ({
      message: "Successfully updated organization.",
    }),
    errorToast: (err) => ({
      message: `Unable to update organization. ${getErrorMessage(err)}`,
    }),
  }
);

export interface CreateOrganiationMutationPayload {
  values: Organization;
}

export const useCreateOrganizationMutation = createAxiosMutation(
  async ({ values }: CreateOrganiationMutationPayload) => {
    const { data } = await instance({ apiNamespace: "support-api" }).post(
      `/organizations`,
      values
    );
    return data;
  },
  {
    successToast: () => ({
      message: "Successfully created organization.",
    }),
    errorToast: (err) => ({
      message: `Unable to create organization. ${getErrorMessage(err)}`,
    }),
  }
);

export const usePMGroupsQuery = createAxiosQuery(
  "pmg-groups",
  async (filters) => {
    const { data } = await instance().get<PMGroup[]>(
      `/organization/pm-groups`,
      {
        params: filters,
      }
    );
    return data;
  }
);

export const usePMGroups = (filters = {}, queryOpts = {}) => {
  const { data } = usePMGroupsQuery(filters, queryOpts);
  return data || [];
};
