import { useEffect } from "react";
import { isUndefined } from "lodash-es";

/**
 * Custom hook to set the document title with a suffix.
 * @param {string} title - The title to set for the document.
 * @example
 * // Sets the document title to "My Page | SmartRent Manager"
 * useDocumentTitle("My Page");
 */
const useDocumentTitle = (title?: string) => {
  useEffect(() => {
    if (!isUndefined(title)) {
      document.title = `${title} | SmartRent Manager`;
    }
  }, [title]);
};

export { useDocumentTitle };
