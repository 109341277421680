import { AxiosError } from "axios";

import { instance } from "@/react/hooks/api";
import {
  createAxiosQuery,
  createAxiosMutation,
} from "@/react/hooks/react-query";
import { getErrorMessage } from "@/react/lib/axios-helpers";
import { typeName } from "@/react/bundles/settings/notifications/utils";

interface NotificationSettingFields {
  email_default: boolean;
  text_default: boolean;
  push_default: boolean;
}

export interface NotificationSetting<
  NotificationType extends keyof typeof typeName = keyof typeof typeName
> extends NotificationSettingFields {
  id: number | null;
  notification_type: NotificationType;
  organization_id: number;
  employee_role_id: number | null;
}

export type NotificationDefaultSettingsResponse = {
  [Type in keyof typeof typeName]: NotificationSetting<Type>;
};

export interface GetNotificationDefaultSettingsPayload {
  roleId: number | string | null | undefined;
}

export interface UpdateNotificationDefaultSettingsPayload {
  roleId: number | string | null | undefined;
  settings: Partial<{
    [Type in keyof typeof typeName]: NotificationSettingFields;
  }>;
}

export const useDefaultNotificationSettingsQuery = createAxiosQuery<
  GetNotificationDefaultSettingsPayload,
  NotificationDefaultSettingsResponse | null
>("group-notification-default-settings", async ({ roleId }) => {
  if (!roleId) {
    return null;
  }

  const { data } = await instance().get<NotificationDefaultSettingsResponse>(
    `/organization/group-notification-default-settings/${roleId}`
  );

  return data;
});

export const useUpdateDefaultNotificationSettingsMutation = createAxiosMutation<
  NotificationDefaultSettingsResponse,
  AxiosError,
  UpdateNotificationDefaultSettingsPayload
>(
  async ({ roleId, settings }) => {
    const { data } = await instance().put<NotificationDefaultSettingsResponse>(
      `/organization/group-notification-default-settings/${roleId}`,
      settings
    );

    return data;
  },
  {
    successToast: () => ({
      message: "Community default notification settings updated successfully.",
    }),
    errorToast: (err) => ({
      message: `Error updating notification settings. ${getErrorMessage(err)}`,
    }),
    onSettled: async (queryClient, _result, _err, { roleId }) => {
      await queryClient.invalidateQueries([
        "group-notification-default-settings",
        { roleId },
      ]);
    },
    onSuccess: (queryClient, result, { roleId }) => {
      queryClient.setQueryData(
        ["group-notification-default-settings", { roleId }],
        result
      );
    },
  }
);
