import React from "react";
import { StyleProp, StyleSheet, Text, View, ViewStyle } from "react-native";

import { useIsDesktop } from "@/react/hooks/breakpoints";

interface CenteredBlockProps {
  children: React.ReactNode;
  style?: StyleProp<ViewStyle>;
}

export const CenteredBlock: React.FC<CenteredBlockProps> = ({
  children,
  style,
}: CenteredBlockProps) => {
  const isDesktop = useIsDesktop();

  return (
    <View style={[styles.root, isDesktop && styles.rootDesktop, style]}>
      {typeof children === "string" ? <Text>{children}</Text> : children}
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    width: "100%",
  },
  rootDesktop: {
    marginHorizontal: "auto",
    // same as the md breakpoint for a smooth transition between md and lg
    maxWidth: 768,
  },
});
