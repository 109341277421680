import { AxiosError } from "axios";
import { ListQueryResponse, PaginationParams, QueryKey } from "@smartrent/ui";
import { useQueryCompat, PaginatedResponse } from "@smartrent/hooks";

import { instance } from "@/react/hooks/api";
import { TourConfiguration, $Unit as UnitProps } from "@/react/types";
import { Tour, UnfinalizedStatus } from "@/react/types/tours/Tour";
import { Prospect } from "@/react/types/tours/Prospect";
import { AmenityType } from "@/react/types/tours/Amenity";
import { getErrorMessage } from "@/react/lib/axios-helpers";
import {
  createAxiosMutation,
  createAxiosQuery,
} from "@/react/hooks/react-query";

import { formatDataPoint, formatTime } from "@/react/lib/number-helpers";
import { filterObject } from "@/react/lib/object-helpers";

export interface ToursDashboardFilters {
  time_period: number;
  group_ids: number[];
  region_ids: number[];
  group_keyword: string;
  exclude_group_ids: number[];
}

type RawWeekday = 0 | 1 | 2 | 3 | 4 | 5 | 6;

export type Weekday =
  | "Sunday"
  | "Monday"
  | "Tuesday"
  | "Wednesday"
  | "Thursday"
  | "Friday"
  | "Saturday";

const weekdays: Record<RawWeekday, Weekday> = {
  0: "Sunday",
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
};

export type TourCountByDateResponse = { date: string; count: number }[];

function toApiParams({
  time_period,
  group_ids,
  region_ids,
  group_keyword,
  exclude_group_ids,
}: ToursDashboardFilters) {
  if (group_ids.length > 0) {
    return {
      time_period,
      group_ids: group_ids.join(","),
    };
  } else {
    return filterObject(
      {
        time_period,
        region_ids: region_ids.length > 0 ? region_ids.join(",") : undefined,
        group_keyword: group_keyword || undefined,
        exclude_group_ids:
          exclude_group_ids.length > 0
            ? exclude_group_ids.join(",")
            : undefined,
      },
      Boolean
    );
  }
}

export const useTourCountByDateQuery = createAxiosQuery<
  ToursDashboardFilters,
  TourCountByDateResponse
>("tour-dashboard/tour-count-by-date", async (filters) => {
  const {
    data: { totals },
  } = await instance().get<{
    totals: { date: string; count: number }[];
  }>("/dashboard/stats/tours/total-tours", { params: toApiParams(filters) });

  return totals;
});

export type CreatedProspectCountResponse = string;

export const useCreatedProspectCountQuery = createAxiosQuery<
  ToursDashboardFilters,
  CreatedProspectCountResponse
>("tour-dashboard/created-prospect-count", async (filters) => {
  const {
    data: { total },
  } = await instance().get<{
    total: number;
  }>("/dashboard/stats/tours/total-prospects", {
    params: toApiParams(filters),
  });

  return total.toLocaleString();
});

export type TourAccessCodesViewedResponse = {
  date: string;
  access_code_views: number;
}[];

export const useTotalTourAccessCodesViewedQuery = createAxiosQuery<
  ToursDashboardFilters,
  TourAccessCodesViewedResponse
>("tour-dashboard/total-codes-viewed", async (filters) => {
  const {
    data: { totals },
  } = await instance().get<{ totals: TourAccessCodesViewedResponse }>(
    "/dashboard/stats/tours/total-codes-viewed",
    {
      params: toApiParams(filters),
    }
  );

  return totals;
});

export type AverageToursPerProspectResponse = string;

export const useAverageToursPerProspectQuery = createAxiosQuery<
  ToursDashboardFilters,
  AverageToursPerProspectResponse
>("tour-dashboard/average-tours-per-prospect", async (filters) => {
  const {
    data: { total },
  } = await instance().get<{
    total: string;
  }>("/dashboard/stats/tours/avg-tours-per-prospect", {
    params: toApiParams(filters),
  });

  return formatDataPoint(total);
});

export type AverageToursPerUnitResponse = string;

export const useAverageToursPerUnitQuery = createAxiosQuery<
  ToursDashboardFilters,
  AverageToursPerUnitResponse
>("tour-dashboard/average-tours-per-unit", async (filters) => {
  const {
    data: { total },
  } = await instance().get<{
    total: string;
  }>("/dashboard/stats/tours/avg-tours-per-unit", {
    params: toApiParams(filters),
  });

  return formatDataPoint(total);
});

export type TourableUnitCountResponse = string;

export type TourNowCountResponse = number;

export type FailedIdCheckResponse = number;

export type AverageMinutesToFinalizeResponse = number;

export const useTourNowCountQuery = createAxiosQuery<
  ToursDashboardFilters,
  TourNowCountResponse
>("tour-dashboard/tour-now-count", async (filters) => {
  const {
    data: { total },
  } = await instance().get<{
    total: number;
  }>("/dashboard/stats/tours/tour-now-count", { params: toApiParams(filters) });

  return total;
});

export const useFailedIdCheckQuery = createAxiosQuery<
  ToursDashboardFilters,
  FailedIdCheckResponse
>("tour-dashboard/failed-id-check", async (filters) => {
  const {
    data: { total },
  } = await instance().get<{
    total: number;
  }>("/dashboard/stats/tours/failed-id-check", {
    params: toApiParams(filters),
  });

  return total;
});

export const useAverageMinutesToFinalizeQuery = createAxiosQuery<
  ToursDashboardFilters,
  AverageMinutesToFinalizeResponse
>("tour-dashboard/avg-min-to-finalize", async (filters) => {
  const {
    data: { total },
  } = await instance().get<{
    total: number;
  }>("/dashboard/stats/tours/avg-min-to-finalize", {
    params: toApiParams(filters),
  });

  return total;
});

export type TourCountByWeekdayResponse = { weekday: Weekday; count: number }[];

export const useTourCountByWeekdayQuery = createAxiosQuery<
  ToursDashboardFilters,
  TourCountByWeekdayResponse
>("tour-dashboard/tour-count-by-weekday", async (filters) => {
  const {
    data: { totals },
  } = await instance().get<{
    totals: { day_of_week: RawWeekday; count: number }[];
  }>("/dashboard/stats/tours/most-popular-days", {
    params: toApiParams(filters),
  });

  return totals.map(({ day_of_week, count }) => ({
    weekday: weekdays[day_of_week],
    count,
  }));
});

export type TourCountByWeekdayAndTimeResponse = {
  weekday: Weekday;
  time: string;
  count: number;
}[];

export const useTourCountByWeekdayAndTimeQuery = createAxiosQuery<
  ToursDashboardFilters,
  TourCountByWeekdayAndTimeResponse
>("tour-dashboard/tour-count-by-weekday-and-time", async (filters) => {
  const {
    data: { totals },
  } = await instance().get<{
    totals: { day_of_week: RawWeekday; tour_time: string; count: number }[];
  }>("/dashboard/stats/tours/most-popular-times", {
    params: toApiParams(filters),
  });

  return totals.map(({ day_of_week, tour_time, count }) => ({
    weekday: weekdays[day_of_week],
    time: formatTime(tour_time),
    count,
  }));
});

export type UnfinalizedTourCountByDateResponse = {
  date: string;
  total_unfinalized_tours: number;
}[];

export const useUnfinalizedTourCountByDateQuery = createAxiosQuery<
  ToursDashboardFilters,
  UnfinalizedTourCountByDateResponse
>("tour-dashboard/unfinalized-tour-count-by-date", async (filters) => {
  const {
    data: { totals },
  } = await instance().get<{
    totals: { date: string; count: number }[];
  }>("/dashboard/stats/tours/total-unfinalized-tours", {
    params: toApiParams(filters),
  });

  return totals.map(({ date, count }) => ({
    date: date,
    total_unfinalized_tours: count,
  }));
});

export type TourFunnelResponse = { tour_step: string; count: number }[];

export const useTourFunnelQuery = createAxiosQuery<
  ToursDashboardFilters,
  TourFunnelResponse
>("tour-dashboard/tour-funnel", async (filters) => {
  const {
    data: { totals },
  } = await instance().get<{
    totals: { tour_step: string; count: number }[];
  }>("/dashboard/stats/tours/tour-funnel", { params: toApiParams(filters) });

  return totals;
});

export type ProspectCountByDateResponse = {
  date: string;
  total_prospects: number;
}[];

export const useProspectCountByDateQuery = createAxiosQuery<
  ToursDashboardFilters,
  ProspectCountByDateResponse
>("tour-dashboard/prospect-count-by-date", async (filters) => {
  const {
    data: { totals },
  } = await instance().get<{
    totals: { date: string; count: number }[];
  }>("/dashboard/stats/tours/total-prospects-by-date", {
    params: toApiParams(filters),
  });

  return totals.map(({ date, count }) => ({
    date: date,
    total_prospects: count,
  }));
});

interface TourConfigResponse {
  tour_config: TourConfiguration;
  tours_base_url: string;
}

export const useGetToursConfig = (groupId: number) =>
  useQueryCompat<
    ["tours-config", number],
    TourConfigResponse,
    AxiosError<unknown>
  >(["tours-config", groupId], async (_key: "tours-config", groupId) => {
    const { data } = await instance().get<TourConfigResponse>(
      `/groups/${groupId}/settings/tours`
    );

    return data;
  });

interface FetchOrganizationTourableGroup {
  id: number;
  marketing_name: string;
  tour_url: string;
}
type FetchOrganizationTourableGroupsResponse =
  ListQueryResponse<FetchOrganizationTourableGroup>;

interface FetchOrganizationHasTourableGroups {
  has_tourable_groups: boolean;
}

interface FetchOrganizationTourableGroupsFilters extends PaginationParams {
  sort?: "space_number";
}

export const useOrganizationTourableGroups = createAxiosQuery(
  "organization-tourable-groups",
  async (filters: FetchOrganizationTourableGroupsFilters) => {
    const { data } =
      await instance().get<FetchOrganizationTourableGroupsResponse>(
        `/groups/tourable-groups`,
        {
          params: filters,
        }
      );

    return data;
  }
);

export const useOrganizationHasTourableGroups = createAxiosQuery(
  "organization-has-tourable-groups",
  async () => {
    const { data } = await instance().get<FetchOrganizationHasTourableGroups>(
      `/organization/tours/has-tourable-groups`
    );

    return data?.has_tourable_groups ?? false;
  }
);

export const useHasOrganizationTourableGroups = () => {
  const { isSuccess, data: tourableGroups } = useOrganizationHasTourableGroups(
    {}
  );

  return isSuccess && tourableGroups;
};

interface FetchToursParams extends PaginationParams {
  status?: string;
}

export const fetchTours = async (
  _key: string,
  { groupId }: { groupId: number | string },
  filters: FetchToursParams
) => {
  const response = await instance().get<ListQueryResponse<Tour>>(
    `/groups/${groupId}/tours`,
    {
      params: {
        is_finalized: filters?.status === UnfinalizedStatus ? false : true,
        ...filters,
      },
    }
  );

  return response.data;
};

export const fetchProspects = async (
  _key: string,
  { groupId }: { groupId: number | string },
  filters: PaginationParams
) => {
  const response = await instance().get<ListQueryResponse<Prospect>>(
    `/groups/${groupId}/prospects`,
    {
      params: {
        ...filters,
      },
    }
  );

  return response.data;
};

export const useAmenitiesQuery = createAxiosQuery(
  "amenities",
  async ({ groupId }: { groupId: number | string }) => {
    const { data } = await instance().get<AmenityType[]>(
      `/groups/${groupId}/amenities`
    );

    return data;
  }
);

export const useUpdateAmenityOrderMutation = createAxiosMutation(
  async ({
    groupId,
    sortedItems,
  }: {
    groupId: number | string;
    sortedItems: AmenityType[];
  }) => {
    const { data } = await instance().patch(
      `/groups/${groupId}/amenities/display-order`,
      sortedItems
    );
    return data;
  },
  {
    errorToast: (err) => ({
      message: `Unable to update amenity order. ${getErrorMessage(err)}`,
    }),
    onSuccess: (queryClient, _result, { groupId }) => {
      queryClient.invalidateQueries([
        "amenities",
        { groupId: String(groupId) },
      ]);
    },
  }
);

export const useCreateAmenityMutation = createAxiosMutation(
  async ({
    group_id,
    amenity,
  }: {
    group_id: number | string;
    amenity: Pick<
      AmenityType,
      "images" | "description" | "name" | "video_link"
    >;
  }) => {
    const { data } = await instance().post(`/groups/${group_id}/amenities`, {
      name: amenity.name,
      description: amenity.description,
      images: amenity.images,
      group_id: group_id,
      video_link: amenity.video_link,
    });
    return data;
  },
  {
    errorToast: (err) => ({
      message: `Unable to create amenity. ${getErrorMessage(err)}`,
    }),
    successToast: () => ({
      message: "Successfully created amenity for this property.",
    }),
    onSuccess: (queryClient, _result, { group_id }) => {
      queryClient.invalidateQueries([
        "amenities",
        { groupId: String(group_id) },
      ]);
    },
  }
);

export const useUpdateAmenityMutation = createAxiosMutation(
  async ({
    id,
    amenity,
  }: {
    id: number | string;
    amenity: Pick<
      AmenityType,
      "images" | "description" | "name" | "video_link" | "group_id"
    >;
  }) => {
    const { data } = await instance().patch(`/amenities/${id}`, {
      name: amenity.name,
      description: amenity.description,
      images: amenity.images,
      group_id: amenity.group_id,
      video_link: amenity.video_link,
    });
    return data;
  },
  {
    errorToast: (err) => ({
      message: `Unable to update amenity. ${getErrorMessage(err)}`,
    }),
    successToast: () => ({
      message: "Successfully updated this amenity.",
    }),
    onSuccess: (queryClient, _result, { amenity }) => {
      queryClient.invalidateQueries([
        "amenities",
        { groupId: String(amenity.group_id) },
      ]);
    },
  }
);

export const useDeleteAmenityMutation = createAxiosMutation(
  async ({ id }: { id: number | string; group_id: number | string }) => {
    const { data } = await instance().delete(`/amenities/${id}`);
    return data;
  },
  {
    errorToast: (err) => ({
      message: `Unable to delete amenity. ${getErrorMessage(err)}`,
    }),
    successToast: () => ({
      message: "Successfully deleted this amenity",
    }),
  }
);

export const fetchUnitAvailability = async (queryKey: QueryKey) => {
  const [, { groupId, debouncedFilters }, filters] = queryKey as [
    string,
    Record<string, any>,
    Record<string, any>
  ];

  const options = {
    params: filters,
  };

  const response = await instance().get<PaginatedResponse<UnitProps>>(
    `/groups/${groupId}/units?qualifies_for_touring=true&marketing_name=${debouncedFilters.marketingName}&unit_status=${debouncedFilters.unitStatus}&available=${debouncedFilters.available}&page=${debouncedFilters.page}`,
    options
  );

  return response.data;
};
