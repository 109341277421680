import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { useQueryCompat } from "@smartrent/hooks";

import { instance } from "@/react/hooks/api";
import { GroupResponse } from "@/react/types/Group";
import { createAxiosQuery } from "@/react/hooks/react-query";
import { Id } from "@/react/types";

function groupQueryKey(
  groupId: Id,
  queryParams?: Record<string, any>
): [string, { params: { groupId: Id; queryParams: Record<string, any> } }] {
  return [
    "groups",
    { params: { groupId: Number(groupId), queryParams: queryParams } },
  ];
}

export const useGroup = (groupId: Id, queryParams?: Record<string, any>) =>
  useQueryCompat(groupQueryKey(groupId, queryParams), async (_key, args) => {
    if (!Number(groupId)) {
      return null;
    }

    const response = await instance().get<GroupResponse>(`/groups/${groupId}`, {
      params: args.params?.queryParams,
    });
    return response.data;
  });

export const useInvalidateGroupQuery = () => {
  const queryClient = useQueryClient();

  return useCallback(
    (groupId: Id, queryParams?: Record<string, any>) => {
      return queryClient.invalidateQueries(groupQueryKey(groupId, queryParams));
    },
    [queryClient]
  );
};

export const useGroupAxiosQuery = createAxiosQuery(
  "groups",
  async ({ groupId }: { groupId?: Id }) =>
    (await instance().get<GroupResponse>(`/groups/${groupId}`)).data
);

export const useUnitStatuses = createAxiosQuery(
  "unit-statuses",
  async ({ groupId }: { groupId?: Id }) =>
    (await instance().get<string[]>(`/groups/${groupId}/unit-statuses`)).data,
  {
    initialData: [],
  }
);
