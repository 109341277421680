import { mapValues } from "lodash-es";

import { Option } from "@/react/hooks/useOptions";

type Transformer = (value: any) => Option[];

const selectOptions =
  (subkey: string, labelAttribute: string) => (value: any) =>
    value?.[subkey]?.flatMap((value: any): any => {
      const attributes = value?.["@attributes"];

      const id = attributes?.id ? String(attributes.id) : null;
      const label = attributes?.[labelAttribute] ?? id;

      return id ? { label: label, value: id } : [];
    });

const transformers: { [key: string]: Transformer } = {
  leadSources: selectOptions("leadSource", "name"),
  eventTypes: selectOptions("eventType", "entrataName"),
};

export const transformEntrataOptions = (
  configurationOptions?: { [key: string]: any }[]
) =>
  mapValues(
    transformers,
    (transformValue: Transformer, key) =>
      transformValue((configurationOptions as any)?.[key]) ?? []
  );
