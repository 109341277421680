import { uniqBy } from "lodash-es";
import { QueryClient } from "@tanstack/react-query";
import { ListQueryResponse, PaginationParams } from "@smartrent/ui";

import { instance } from "@/react/hooks/api";
import { getErrorMessage } from "@/react/lib/axios-helpers";
import {
  createAxiosMutation,
  createAxiosQuery,
  invalidateQueries,
} from "@/react/hooks/react-query";
import { ParkingAccessGroup } from "@/react/types";

import { ParkingQueryKeys } from "../parking/parking-query-keys";

export interface ListParkingAccessGroupsProps {
  groupId: number;
  filters?: ListParkingAccessGroupsFilters;
}

export interface ListParkingAccessGroupsFilters extends PaginationParams {
  name?: string;
}

const getParkingAccessGroupUrl = (groupId: number) =>
  `/groups/${groupId}/parking-access-groups`;

export async function listParkingAccessGroups({
  groupId,
  filters,
}: ListParkingAccessGroupsProps) {
  const { data } = await instance().get<ListQueryResponse<ParkingAccessGroup>>(
    getParkingAccessGroupUrl(groupId),
    { params: filters }
  );

  const records: ParkingAccessGroup[] = data.records.map((pag) => {
    const sections = pag.areas.flatMap((a) => a.sections || []);
    const areaSpaces = pag.areas.flatMap((a) => a.spaces || []);
    const sectionSpaces = pag.areas.flatMap(
      (a) => a.sections?.flatMap((s) => s.spaces || []) || []
    );
    const spaces = uniqBy([...areaSpaces, ...sectionSpaces], "id");
    return {
      ...pag,
      sections,
      spaces,
    };
  });

  return {
    ...data,
    records,
  };
}

export const useParkingAccessGroupsQuery = createAxiosQuery<
  ListParkingAccessGroupsProps,
  ListQueryResponse<ParkingAccessGroup>
>(ParkingQueryKeys.ParkingAccessGroups, listParkingAccessGroups);

export const useCreateParkingAccessGroupMutation = createAxiosMutation(
  async ({
    groupId,
    parkingAccessGroup,
  }: {
    groupId: number;
    parkingAccessGroup: Partial<ParkingAccessGroup>;
  }) => {
    const { data } = await instance().post<ParkingAccessGroup>(
      `/groups/${groupId}/parking-access-groups`,
      parkingAccessGroup
    );

    return data;
  },
  {
    successToast: () => ({
      message: "Successfully created parking access group.",
    }),
    errorToast: (err) => ({
      message: `Error creating parking access group. ${getErrorMessage(err)}`,
    }),
    onSettled: (queryClient) => {
      invalidateParkingAccessGroupQueries(queryClient);
    },
  }
);

export const useUpdateParkingAccessGroupMutation = createAxiosMutation(
  async ({
    groupId,
    parkingAccessGroup,
  }: {
    groupId: number;
    parkingAccessGroup: Partial<ParkingAccessGroup>;
  }) => {
    const { data } = await instance().patch<ParkingAccessGroup>(
      `/groups/${groupId}/parking-access-groups/${parkingAccessGroup.id}`,
      parkingAccessGroup
    );

    return data;
  },
  {
    successToast: () => ({
      message: "Successfully updated parking access group.",
    }),
    errorToast: (err) => ({
      message: `Error updating parking access group. ${getErrorMessage(err)}`,
    }),
    onSettled: (queryClient) => {
      invalidateParkingAccessGroupQueries(queryClient);
    },
  }
);

export const useDeleteParkingAccessGroupMutation = createAxiosMutation(
  async ({
    groupId,
    parkingAccessGroup,
  }: {
    groupId: number;
    parkingAccessGroup: Partial<ParkingAccessGroup>;
  }) => {
    const { data } = await instance().delete<ParkingAccessGroup>(
      `/groups/${groupId}/parking-access-groups/${parkingAccessGroup.id}`
    );

    return data;
  },
  {
    successToast: () => ({
      message: "Successfully removed parking access group.",
    }),
    errorToast: (err) => ({
      message: `Error removing parking access group. ${getErrorMessage(err)}`,
    }),
    onSettled: (queryClient) => {
      invalidateParkingAccessGroupQueries(queryClient);
    },
  }
);

const invalidateParkingAccessGroupQueries = async (queryClient: QueryClient) =>
  invalidateQueries(queryClient, [ParkingQueryKeys.ParkingAccessGroups]);
