import { useQuery } from "@tanstack/react-query";

import { instance } from "@/react/hooks/api";
import { ParkingGroup } from "@/react/types";
import { createAxiosMutation } from "@/react/hooks/react-query";
import { getErrorMessage } from "@/react/lib/axios-helpers";

import { ParkingQueryKeys } from "./parking-query-keys";

export const useParkingGroupQuery = (groupId: number) =>
  useQuery([ParkingQueryKeys.ParkingGroup, { groupId }], async () => {
    const response = await instance().get<{ property: ParkingGroup }>(
      `/groups/${groupId}/parking`
    );

    return response?.data?.property ?? null;
  });

export const useUpdateParkingGroupMutation = createAxiosMutation(
  async ({
    group,
    groupId,
  }: {
    groupId: number;
    group: Partial<ParkingGroup>;
  }) => {
    const response = await instance().patch(
      `/groups/${groupId}/parking`,
      group
    );
    return response;
  },
  {
    successToast: () => ({
      message: "Successfully updated parking group.",
    }),
    errorToast: (err) => ({
      message: `Error updating parking group. ${getErrorMessage(err)}`,
    }),
    onSettled: async (queryClient, _data, _error, { groupId }) => {
      await queryClient.invalidateQueries([
        ParkingQueryKeys.ParkingGroup,
        { groupId },
      ]);
    },
  }
);
