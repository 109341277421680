export enum ProviderName {
  AlloyAccess = "Alloy Access",
  AlloyAccessSalto = "Alloy Access - Salto",
  AlloyAccessSchlage = "Alloy Access - Schlage",
  AlloyAccessYale = "Alloy Access - Yale",
  AnyoneHome = "Anyone Home",
  AppFolio = "AppFolio",
  Atlas = "Atlas",
  AvalonBay = "Avalon Bay",
  BDX = "BDX",
  Brivo = "Brivo",
  ButterflyMX = "ButterflyMX",
  Engrain = "Engrain",
  Entrata = "Entrata",
  Funnel = "Funnel",
  HarborGroup = "Harbor Group",
  HeroLabs = "Hero Labs",
  Honeywell = "Honeywell",
  ILM = "ILM",
  IglooHome = "Igloo Home",
  Knock = "Knock",
  Lasso = "Lasso",
  Lennar = "Lennar",
  LiftMaster = "LiftMaster",
  RGNets = "Community WiFi - RG Nets",
  RealPage = "RealPage",
  RentCafe = "RentCafe",
  RentDynamics = "Rent Dynamics",
  RentEGI = "RentEGI",
  RentRoll = "RentRoll",
  ResMan = "ResMan",
  Ring = "Ring",
  RoamingiQ = "Community WiFi - RoamingiQ",
  SightPlan = "SightPlan",
  Venterra = "Venterra",
  Yardi = "Yardi Voyager",
}

export enum ProviderCustomConfiguration {
  SpaceReservationSync = "space_reservations_sync_enabled",
}

export const PropertyAccess = [
  ProviderName.AlloyAccess,
  ProviderName.AlloyAccessSalto,
  ProviderName.AlloyAccessSchlage,
  ProviderName.Brivo,
  ProviderName.IglooHome,
];

export const UnitAccess = [
  ProviderName.AlloyAccessSalto,
  ProviderName.AlloyAccessSchlage,
  ProviderName.AlloyAccessYale,
  ProviderName.IglooHome,
];

export const AccessControlIntegrations = [
  ProviderName.Brivo,
  ProviderName.IglooHome,
  ProviderName.AlloyAccess,
  ProviderName.AlloyAccessSalto,
  ProviderName.AlloyAccessSchlage,
  ProviderName.AlloyAccessYale,
];

type ImageType = "png" | "svg";

const integrationLogoImages: {
  [providerName in ProviderName]?: { [type in ImageType]?: string };
} = {
  [ProviderName.AlloyAccessSalto]: {
    png: "alloy-logo.png",
    svg: "salto-logo.svg",
  },
  [ProviderName.AlloyAccessSchlage]: {
    png: "schlage-logo.png",
    svg: "schlage-logo.svg",
  },
  [ProviderName.AlloyAccessYale]: {
    png: "yale-logo.png",
    svg: "yale-logo.svg",
  },
  [ProviderName.AlloyAccess]: { png: "alloy-logo.png", svg: "alloy.svg" },
  [ProviderName.AnyoneHome]: { svg: "anyone-home.svg" },
  [ProviderName.AppFolio]: { png: "appfolio.png" },
  [ProviderName.Atlas]: { png: "atlas-logo.png", svg: "atlas.svg" },
  [ProviderName.AvalonBay]: { png: "avalon-bay-logo.png" },
  [ProviderName.BDX]: { png: "bdx.png" },
  [ProviderName.Brivo]: { png: "brivo-logo.png", svg: "brivo.svg" },
  [ProviderName.ButterflyMX]: {
    png: "butterfly-mx-logo.png",
    svg: "butterfly-mx-logo.svg",
  },
  [ProviderName.Engrain]: { png: "engrain-logo.png", svg: "engrain-logo.svg" },
  [ProviderName.Entrata]: { png: "entrata-logo.png", svg: "entrata.svg" },
  [ProviderName.Funnel]: { png: "funnel-logo.png" },
  [ProviderName.HarborGroup]: {
    png: "harbor-group-logo.png",
    svg: "harbor-group-logo.svg",
  },
  [ProviderName.HeroLabs]: { png: "hero-labs-logo.svg" },
  [ProviderName.Honeywell]: { png: "honeywell-logo.png" },
  [ProviderName.ILM]: { png: "ILM.png" },
  [ProviderName.IglooHome]: {
    png: "igloohome-logo.png",
    svg: "igloo_home.svg",
  },
  [ProviderName.Knock]: { png: "knock-logo.png", svg: "knock.svg" },
  [ProviderName.Lasso]: { png: "lasso-logo.png", svg: "lasso.svg" },
  [ProviderName.Lennar]: { png: "lennar-logo.png", svg: "lennar.svg" },
  [ProviderName.LiftMaster]: { svg: "lift-master.svg" },
  [ProviderName.RGNets]: { svg: "rg-nets.svg" },
  [ProviderName.RealPage]: { png: "realpage-logo.png", svg: "realpage.svg" },
  [ProviderName.RentCafe]: { png: "rentcafe.png" },
  [ProviderName.RentDynamics]: { png: "rentdynamics.svg" },
  [ProviderName.RentEGI]: { png: "rent-egi-logo.png", svg: "rent_egi.svg" },
  [ProviderName.RentRoll]: { png: "rent-roll-logo.png", svg: "rent_roll.svg" },
  [ProviderName.ResMan]: { png: "resman-logo.png", svg: "resman.svg" },
  [ProviderName.Ring]: { png: "ring-logo.png", svg: "ring.svg" },
  [ProviderName.RoamingiQ]: { svg: "roaming-iq.svg" },
  [ProviderName.SightPlan]: { png: "sightplan-logo.png", svg: "sightplan.svg" },
  [ProviderName.Venterra]: { png: "venterra-logo.png", svg: "venterra.svg" },
  [ProviderName.Yardi]: { png: "yardi-logo.png", svg: "yardi.svg" },
};

const placeholderLogos: { [type in ImageType]: string } = {
  png: "placeholder-logo.png",
  svg: "_placeholder.svg",
};

export const getIntegrationLogoUri = (
  providerName: ProviderName,
  acceptableTypes: ImageType[] = ["svg", "png"]
) => {
  const providerLogos = integrationLogoImages[providerName];
  const type =
    providerLogos && acceptableTypes.find((type) => providerLogos[type]);
  const file =
    type && providerLogos
      ? providerLogos[type]
      : placeholderLogos[acceptableTypes[0]];

  return `/images/integrations/${file}`;
};

export const IntegrationsDesignV2 = [
  ProviderName.AlloyAccess,
  ProviderName.AlloyAccessSalto,
  ProviderName.AlloyAccessSchlage,
  ProviderName.AlloyAccessYale,
  ProviderName.AnyoneHome,
  ProviderName.AppFolio,
  ProviderName.Atlas,
  ProviderName.AvalonBay,
  ProviderName.BDX,
  ProviderName.Brivo,
  ProviderName.ButterflyMX,
  ProviderName.Engrain,
  ProviderName.Entrata,
  ProviderName.Funnel,
  ProviderName.HarborGroup,
  ProviderName.HeroLabs,
  ProviderName.Honeywell,
  ProviderName.ILM,
  ProviderName.IglooHome,
  ProviderName.Knock,
  ProviderName.Lasso,
  ProviderName.Lennar,
  ProviderName.LiftMaster,
  ProviderName.RGNets,
  ProviderName.RealPage,
  ProviderName.RentCafe,
  ProviderName.RentDynamics,
  ProviderName.RentEGI,
  ProviderName.RentRoll,
  ProviderName.ResMan,
  ProviderName.Ring,
  ProviderName.RoamingiQ,
  ProviderName.SightPlan,
  ProviderName.Venterra,
  ProviderName.Yardi,
];

export const AlloyAccessProviders = [
  ProviderName.AlloyAccess,
  ProviderName.AlloyAccessSalto,
  ProviderName.AlloyAccessSchlage,
  ProviderName.AlloyAccessYale,
];

export interface Provider {
  id: number;
  provider_name: ProviderName;
  provider_slug: string;
  provider_metadata: {
    provider_credentials: Record<string, string>;
  };
  provider_type: string;
  configurations?: ProviderConfiguration[] | null;
}

interface ThirdPartyProvider {
  id: number;
  provider_name: ProviderName;
  provider_type: string;
  provider_slug: string;
}
export interface ProviderConfiguration<
  CustomProviderConfiguration = Record<string, any>
> {
  id: number;
  provider_credentials: Record<string, string>;
  credential: {
    id: number;
    name: string;
    type: string;
  };
  custom_provider_configuration: CustomProviderConfiguration;
  maelstrom_integration_id: number | undefined;
  enabled?: boolean;
  locked?: boolean;
  credential_id?: number;
  provider: ThirdPartyProvider;
}

export interface Credential {
  id: number;
  organization_id: number;
  name: string;
  type: string;
}

export type IntegrationProvider =
  | ProviderName.AlloyAccess
  | ProviderName.AlloyAccessSalto
  | ProviderName.AlloyAccessSchlage
  | ProviderName.AlloyAccessYale
  | ProviderName.AnyoneHome
  | ProviderName.Atlas
  | ProviderName.Entrata
  | ProviderName.Funnel
  | ProviderName.Knock
  | ProviderName.Lasso
  | ProviderName.Lennar
  | ProviderName.RealPage
  | ProviderName.ResMan
  | ProviderName.RentDynamics
  | ProviderName.RentEGI
  | ProviderName.RentRoll
  | ProviderName.RGNets
  | ProviderName.RoamingiQ
  | ProviderName.Ring
  | ProviderName.Yardi;

export type IntegrationError =
  | "unauthorized"
  | "not_configured"
  | "config_loading";

export const CredentialProviders: Record<string, boolean> = {
  [ProviderName.AppFolio]: true,
  [ProviderName.AnyoneHome]: true,
  [ProviderName.Entrata]: true,
  [ProviderName.RealPage]: true,
  [ProviderName.RentDynamics]: true,
  [ProviderName.RentEGI]: true,
  [ProviderName.RentRoll]: true,
  [ProviderName.ResMan]: true,
  [ProviderName.SightPlan]: true,
  [ProviderName.Yardi]: true,
};

export const MultiCredentialProviders = [
  ProviderName.AnyoneHome,
  ProviderName.Entrata,
  ProviderName.RealPage,
  ProviderName.RentDynamics,
  ProviderName.RentEGI,
  ProviderName.ResMan,
  ProviderName.Venterra,
  ProviderName.Yardi,
  ProviderName.AppFolio,
];

export const PmsProviders = [
  ProviderName.AppFolio,
  ProviderName.Atlas,
  ProviderName.Entrata,
  ProviderName.Funnel,
  ProviderName.Knock,
  ProviderName.Lasso,
  ProviderName.Lennar,
  ProviderName.RealPage,
  ProviderName.RentEGI,
  ProviderName.RentRoll,
  ProviderName.ResMan,
  ProviderName.Venterra,
  ProviderName.Yardi,
];
