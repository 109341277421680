import { View, Text, StyleSheet } from "react-native";
import { useTheme } from "@smartrent/ui";
import { ExclamationCircleSolid } from "@smartrent/icons";

const AccessDenied = () => {
  const { colors } = useTheme();

  return (
    <View style={styles.container}>
      <View>
        <ExclamationCircleSolid size={125} color={colors.error} />
      </View>
      <View>
        <Text style={[styles.type, { color: colors.error }]}>
          Access Denied
        </Text>
      </View>
      <View>
        <Text style={styles.message}>
          You do not have the appropriate permissions to view this page. Please
          contact your organization's admin.
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignSelf: "center",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 32,
    maxWidth: "512px",
  },
  type: {
    marginTop: 32,
    fontSize: 36,
    fontWeight: "bold",
  },
  message: {
    textAlign: "center",
    marginHorizontal: 50,
    marginVertical: 16,
    fontSize: 24,
  },
});

export default AccessDenied;
