import React, { createContext, useContext, useEffect, useState } from "react";
import { ZendeskAPI } from "react-zendesk";
import * as Sentry from "@sentry/react";

import { useCurrentUser } from "@/react/context/mgmt-api/user";
import { useGetConfiguration } from "@/react/queries/mgmt-api/configuration";
import { useOrganization } from "@/react/queries/mgmt-api/organization";
import { useFeatureFlagEnabled } from "@/react/queries/mgmt-api/feature-flags";

interface ZendeskChatContextProps {
  zendesk_embed_key?: string;
  enabled: boolean;
  shouldShow: boolean;
  setShouldShow: (shouldShow: boolean) => void;
  isMounted: boolean;
  setIsMounted: (isMounted: boolean) => void;
}

const defaultValue: ZendeskChatContextProps = {
  zendesk_embed_key: undefined,
  enabled: false,
  shouldShow: false,
  setShouldShow: (_: boolean) => {
    // default empty function
  },
  isMounted: false,
  setIsMounted: (_: boolean) => {
    // default empty function
  },
};

const ZendeskChatContext = createContext<ZendeskChatContextProps>(defaultValue);

export const useZendeskChat = () => useContext(ZendeskChatContext);

/**
 * Only use this in the mgmt workspace.
 */
export const ZendeskChatProvider: React.FC = ({ children }) => {
  // determine if the chat should be loaded and mounted
  const [enabled, setEnabled] = useState(false);
  // if the chat is loaded and mounted in the dom
  const [isMounted, setIsMounted] = useState(false);
  // if the chat should be visible
  const [shouldShow, setShouldShow] = useState(false);

  const currentUser = useCurrentUser();
  const { data: config } = useGetConfiguration();

  const { data: organization } = useOrganization();
  const { id: organizationId } = organization ?? {};

  const { data: featureFlag } = useFeatureFlagEnabled({
    flag: "zendesk_chat",
    organizationId,
  });

  const zendesk_embed_key = config?.zendesk_embed_key;

  useEffect(() => {
    if (
      featureFlag?.enabled &&
      zendesk_embed_key &&
      currentUser?.id &&
      !currentUser.is_smartrent_staff
    ) {
      setEnabled(true);
    }
  }, [featureFlag, zendesk_embed_key, currentUser]);

  useEffect(() => {
    if (enabled && isMounted && window.zE) {
      // check if classic chat is enabled
      if (window.zE.widget === "classic") {
        Sentry.captureMessage("Zendesk chat is not configured correctly", {
          level: Sentry.Severity.Error,
        });
        // hide the chat if classic chat is enabled
        ZendeskAPI("webWidget", "hide");
        return;
      }

      // try to show or hide the chat based on the shouldShow state
      ZendeskAPI("messenger", shouldShow ? "show" : "hide");
    }
  }, [enabled, isMounted, shouldShow]);

  useEffect(() => {
    if (
      enabled &&
      isMounted &&
      window.zE &&
      window.zE.widget === "messenger" &&
      currentUser?.zendesk_chat_jwt_token
    ) {
      // if messaging, try to login
      try {
        ZendeskAPI(
          "messenger",
          "loginUser",
          function (callback: (token: string | null) => void) {
            callback(currentUser.zendesk_chat_jwt_token);
          }
        );
      } catch (error) {
        Sentry.captureException(error);
      }
    }
  }, [currentUser, enabled, isMounted]);

  return (
    <ZendeskChatContext.Provider
      value={{
        zendesk_embed_key,
        enabled,
        shouldShow,
        setShouldShow,
        isMounted,
        setIsMounted,
      }}
    >
      {children}
    </ZendeskChatContext.Provider>
  );
};
