import { AxiosError } from "axios";

import { instance } from "@/react/hooks/api";
import {
  createAxiosQuery,
  createAxiosMutation,
} from "@/react/hooks/react-query";
import { getErrorMessage } from "@/react/lib/axios-helpers";
import {
  CommunityDefault,
  CommunityDefaultSettingType,
} from "@/react/types/CommunityDefault";

export interface ProspectInformationConfiguration {
  id: number;
  group_id: number;
  prospect_address_enabled: boolean;
  prospect_address_required: boolean;
  prospect_city_enabled: boolean;
  prospect_city_required: boolean;
  prospect_state_enabled: boolean;
  prospect_state_required: boolean;
  prospect_zip_code_enabled: boolean;
  prospect_zip_code_required: boolean;
  prospect_desired_move_in_date_enabled: boolean;
  prospect_desired_move_in_date_required: boolean;
  prospect_desired_number_of_bedrooms_enabled: boolean;
  prospect_desired_number_of_bedrooms_required: boolean;
  realtor_info_enabled: boolean;
  is_real_estate_agent_enabled: boolean;
  realtor_instructions: string;
}

type DefaultableConfiguration = Partial<
  Omit<ProspectInformationConfiguration, "id" | "group_id">
>;

export type CommunityDefaultResponse = CommunityDefault<
  CommunityDefaultSettingType.prospect_information,
  DefaultableConfiguration
>;

export const useDefaultProspectInformationConfiguration = createAxiosQuery<
  void,
  CommunityDefaultResponse
>("community-defaults-prospect-information", async () => {
  const { data } = await instance().get<CommunityDefaultResponse>(
    "/organization/community-defaults/prospect-information-config"
  );

  return data;
});

export const useUpdateDefaultProspectInformationConfiguration =
  createAxiosMutation<
    CommunityDefaultResponse,
    AxiosError,
    Pick<CommunityDefaultResponse, "defaults">
  >(
    async ({ defaults }) => {
      const { data } = await instance().patch<CommunityDefaultResponse>(
        "/organization/community-defaults/prospect-information-config",
        defaults
      );

      return data;
    },
    {
      successToast: () => ({
        message:
          "Community default prospect information settings updated successfully.",
      }),
      errorToast: (err) => ({
        message: `Error updating prospect information settings. ${getErrorMessage(
          err
        )}`,
      }),
      onSettled: async (queryClient) => {
        await queryClient.invalidateQueries([
          "community-defaults-prospect-information",
        ]);
      },
      onSuccess: (queryClient, result) => {
        queryClient.setQueryData(
          ["community-defaults-prospect-information"],
          result
        );
      },
    }
  );
