import { QueryFunctionContext, useQuery } from "@tanstack/react-query";
import { ListQueryResponse, PaginationParams } from "@smartrent/ui";

import { instance } from "@/react/hooks/api";
import { Rate } from "@/react/types";
import { createAxiosMutation } from "@/react/hooks/react-query";
import { getErrorMessage } from "@/react/lib/axios-helpers";

import { ParkingQueryKeys } from "./parking-query-keys";

interface Filters extends PaginationParams {
  name: string;
  space_number: string;
  section_name: string;
}

export const fetchParkingRates = async ({ queryKey }: QueryFunctionContext) => {
  const [, { groupId }, filters] = queryKey as [
    any,
    { groupId: number },
    Filters
  ];
  const response = await instance().get<ListQueryResponse<Rate>>(
    `/groups/${groupId}/parking/rates`,
    {
      params: filters,
    }
  );
  return response.data;
};

export const useParkingRateQuery = ({
  rateId,
  groupId,
}: {
  rateId?: string;
  groupId: number;
}) =>
  useQuery(
    [ParkingQueryKeys.Rate, { rateId, groupId }],
    async () => {
      const response = await instance().get<Rate>(
        `/groups/${groupId}/parking/rates/${rateId}`
      );
      return response.data;
    },
    {
      enabled: !!rateId,
    }
  );

export const useCreateParkingRateMutation = createAxiosMutation(
  async ({ rate, groupId }: { groupId: number; rate: Partial<Rate> }) => {
    const response = await instance().post(
      `/groups/${groupId}/parking/rates`,
      rate
    );
    return response;
  },
  {
    successToast: () => ({
      message: "Successfully created rate.",
    }),
    errorToast: (err) => ({
      message: `Unable to create rate. ${getErrorMessage(err)}`,
    }),
    onSettled: async (queryClient, _data, _error, { groupId }) => {
      queryClient.invalidateQueries([ParkingQueryKeys.Rates, { groupId }]);
    },
  }
);

export const useUpdateParkingRateMutation = createAxiosMutation(
  async ({ rate, groupId }: { groupId: number; rate: Partial<Rate> }) => {
    const response = await instance().put(
      `/groups/${groupId}/parking/rates/${rate.id}`,
      rate
    );
    return response;
  },
  {
    successToast: () => ({
      message: "Successfully updated rate.",
    }),
    errorToast: (err) => ({
      message: `Unable to update rate. ${getErrorMessage(err)}`,
    }),
    onSettled: async (queryClient, _data, _error, { rate, groupId }) => {
      queryClient.invalidateQueries([ParkingQueryKeys.Rates, { groupId }]);
      queryClient.invalidateQueries([
        ParkingQueryKeys.Rate,
        { rateId: rate.id },
      ]);
    },
  }
);

export const useDeleteParkingRateMutation = createAxiosMutation(
  async ({ groupId, rateId }: { groupId: number; rateId: string }) => {
    const response = await instance().delete(
      `/groups/${groupId}/parking/rates/${rateId}`
    );
    return response;
  },
  {
    successToast: () => ({
      message: "Successfully deleted rate.",
    }),
    errorToast: (err) => ({
      message: `Unable to delete rate. ${getErrorMessage(err)}`,
    }),
    onSettled: async (queryClient, _data, _error, { groupId, rateId }) => {
      queryClient.invalidateQueries([ParkingQueryKeys.Rates, { groupId }]);
      queryClient.invalidateQueries([ParkingQueryKeys.Rate, { rateId }]);
    },
  }
);
