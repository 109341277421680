import { AxiosError } from "axios";
import { useQueryClient, useQuery } from "@tanstack/react-query";

import { QueryKey, ListQueryResponse, PaginationParams } from "@smartrent/ui";

import { instance } from "@/react/hooks/api";
import {
  createAxiosQuery,
  createAxiosMutation,
} from "@/react/hooks/react-query";
import { Role } from "@/react/types/Role";
import { getErrorMessage } from "@/react/lib/axios-helpers";

interface BaseRole {
  id: number;
  name: string;
  organization_id: number;
}

interface EmailLog {
  id: number | string;
  email: string;
  event: string;
}

export type EmployeeRole = Role & BaseRole;

export interface ListRolesFilters extends PaginationParams {
  name?: string;
}

interface GetRolePayload {
  roleId?: number | string;
}

export const useRoleQuery = createAxiosQuery<GetRolePayload, EmployeeRole>(
  "role",
  async ({ roleId }) => {
    const { data } = await instance().get<EmployeeRole>(`/roles/${roleId}`);
    return data;
  }
);

export const useRolesQuery = createAxiosQuery<
  any,
  ListQueryResponse<EmployeeRole>
>("roles", async () => {
  const data = await fetchRoles({ queryKey: ["", "", { page: 1 }] });
  return data;
});

export interface CreateRoleMutationPayload {
  values: {
    name: string;
  };
}

export const useCreateRoleMutation = createAxiosMutation<
  Role,
  AxiosError,
  CreateRoleMutationPayload
>(
  async ({ values }) => {
    const { data } = await instance().post<Role>("/roles", values);
    return data;
  },
  {
    successToast: () => ({
      message: "Successfully created role.",
    }),
    errorToast: (err) => ({
      message: `Unable to create role. ${getErrorMessage(err)}`,
    }),
    onSuccess: (queryClient, result) => {
      queryClient.setQueryData(["roles"], result);
    },
  }
);

export async function fetchRoles({ queryKey }: { queryKey: QueryKey }) {
  const [, , filters] = queryKey as [string, string, ListRolesFilters];
  const { data } = await instance().get("/roles", {
    params: filters,
  });

  return data as ListQueryResponse<EmployeeRole>;
}

export async function fetchRolesPaginated(params: ListRolesFilters) {
  const { data } = await instance().get(`/roles`, { params });
  return data;
}

export const useInvalidateGetRoleQuery = (roleId: number | string) => {
  const queryClient = useQueryClient();
  return () => queryClient.invalidateQueries(["role", { roleId }]);
};

export const useInvalidateAllRolesQuery = () => {
  const queryClient = useQueryClient();
  return () => queryClient.invalidateQueries(["roles"]);
};

interface UpdateRolePayload {
  id: number | string;
  values: Role;
}

export const useUpdateRoleMutation = createAxiosMutation(
  async ({ id, values }: UpdateRolePayload) => {
    const { data } = await instance().patch(`/roles/${id}`, values);
    return data;
  },
  {
    successToast: () => ({
      message: "Successfully updated role.",
    }),
    errorToast: (err) => ({
      message: `Unable to update role. ${getErrorMessage(err)}`,
    }),
    onSuccess: (queryClient, result, { id }) => {
      // typecast roleId to a string to match the useRole query where roleId comes from url params
      queryClient.setQueryData(["role", { roleId: String(id) }], result);
    },
  }
);

export const useEmailBounceLogQuery = (email: string) =>
  useQuery(["email-logs"], async () => {
    const { data } = await instance().get<Promise<EmailLog>>(
      `/email-webhook-logs/${email}`
    );

    return data;
  });
