import { useQueryClient } from "@tanstack/react-query";

import { PaginationParams, ListQueryResponse } from "@smartrent/ui";

import { instance } from "@/react/hooks/api";
import { createAxiosQuery } from "@/react/hooks/react-query";

interface ListProviderConfigurationsFilter extends PaginationParams {
  enabled?: boolean;
  providers_that_support?: string;
}

interface ProviderConfiguration {
  id: number;
  group_id: number;
  enabled: boolean;
}

export const useProviderConfigurationsQuery = createAxiosQuery(
  "org-provider-configurations",
  async (filters: ListProviderConfigurationsFilter = {}) => {
    const response = await instance().get<
      ListQueryResponse<ProviderConfiguration>
    >("/provider-configurations", { params: filters });

    return response.data;
  }
);

export const useInvalidateProviderConfigurationsQuery = () => {
  const queryClient = useQueryClient();
  return () => {
    queryClient.invalidateQueries(["org-provider-configurations"]);
    queryClient.invalidateQueries(["provider-configurations"]);
  };
};
