import { useCallback } from "react";
import { QueryClient, useQuery, useQueryClient } from "@tanstack/react-query";
import { useSelectQuery } from "@smartrent/ui";
import { PaginatedResponse, useReducedInfiniteQuery } from "@smartrent/hooks";

import {
  createAxiosMutation,
  invalidateQueries,
} from "@/react/hooks/react-query";
import { getErrorMessage } from "@/react/lib/axios-helpers";
import { instance } from "@/react/hooks/api";
import {
  Space,
  SpaceType,
  BookingType,
  ConstructionType,
  SpaceStatus,
  SpaceUsageStats,
  SpaceActivityStats,
  Violation,
  Area,
  Section,
  Vehicle,
  Decal,
  EngrainSpace,
  Id,
} from "@/react/types";

import { ParkingQueryKeys } from "./parking-query-keys";

export type ListSpaceItem = Space & {
  all_users: number;
  area: Area | null;
  section: Pick<Section, "id" | "name" | "booking_type" | "rate"> | null;
  vehicles: (Pick<
    Vehicle,
    "id" | "license_plate" | "state" | "make" | "model" | "year" | "color"
  > & { decals: Pick<Decal, "id" | "code">[] | null })[];
};
interface ListSpacesResponse {
  records: ListSpaceItem[];
  current_page: number;
  total_records: number;
  total_pages: number;
}

export interface SpaceFilterOptions {
  space_number?: string;
  area_name?: string;
  type?: SpaceType;
  booking_type?: BookingType;
  construction_type?: ConstructionType;
  status?: SpaceStatus;
  section_id?: string;
  exclude_section_id?: string;
  exclude_has_rates?: string;
  has_sensor?: boolean;
  active?: boolean;
  page?: number;
  limit?: number;
  sort?: "space_number";
  dir?: "asc" | "desc";
}

export async function listSpaces(
  _key: ParkingQueryKeys.Spaces,
  { groupId }: { groupId: number },
  filters: SpaceFilterOptions
) {
  const { data } = await instance().get<ListSpacesResponse>(
    `/groups/${groupId}/parking/spaces`,
    {
      params: filters,
    }
  );

  return data;
}

export const useSpacesQuery = (groupId: number, filters: SpaceFilterOptions) =>
  useQuery(
    [ParkingQueryKeys.Spaces, { groupId }, filters],
    async () => await listSpaces(ParkingQueryKeys.Spaces, { groupId }, filters)
  );

export const useSpacesInfiniteQuery = (
  groupId: number,
  filters: SpaceFilterOptions = {}
) =>
  useReducedInfiniteQuery(
    [ParkingQueryKeys.Spaces, { groupId, ...filters }],
    async ({ pageParam }) => {
      const { data } = await instance().get<ListSpacesResponse>(
        `/groups/${groupId}/parking/spaces`,
        {
          params: { ...filters, page: pageParam },
        }
      );

      return data;
    }
  );

export const useSpaceQuery = (groupId: number, spaceId: string) =>
  useQuery(
    [ParkingQueryKeys.Space, { groupId, spaceId }],
    async () => {
      const { data } = await instance().get<
        Space & {
          active_violation: Violation | null;
          area: Area | null;
          section: Pick<
            Section,
            "id" | "name" | "booking_type" | "rate"
          > | null;
          sensor_online: boolean | null;
          sensor_id: string | null;
          is_private: boolean;
        }
      >(`/groups/${groupId}/parking/spaces/${spaceId}`);

      return data;
    },
    {
      enabled: !!spaceId,
    }
  );

export const useSpaceMapQuery = (groupId: number) =>
  useQuery(
    [ParkingQueryKeys.Spaces, { groupId }],
    async () => {
      const { data } = await instance().get<{
        records: EngrainSpace[];
      }>(`/groups/${groupId}/parking/spaces/map-units`);

      return data;
    },
    {
      enabled: !!groupId,
    }
  );

export const useInvalidateSpacesQuery = () => {
  const queryClient = useQueryClient();

  return useCallback(
    (groupId: number) =>
      queryClient.invalidateQueries([ParkingQueryKeys.Spaces, { groupId }]),
    [queryClient]
  );
};

export const useRevenueStatsQuery = (
  groupId: number,
  spaceId: string,
  dateRangeValue: number
) =>
  useQuery(
    [ParkingQueryKeys.Spaces, { groupId, spaceId, dateRangeValue }],
    async () => {
      const response = await instance().get<any>(
        `/groups/${groupId}/parking/spaces/${spaceId}/revenue-stats`,
        { params: { date_range_value: dateRangeValue } }
      );
      return (
        response?.data ?? {
          avg_session_revenue: null,
          daily_revenue_for_last_thirty_days: [],
          last_thirty_days_of_revenue: null,
          most_profitable_hour_of_day: null,
          total_revenue: null,
        }
      );
    }
  );

export const useSpaceUsageStatsQuery = (groupId: Id, spaceId: Id) =>
  useQuery([ParkingQueryKeys.SpaceUsage, { groupId, spaceId }], async () => {
    const {
      data: { stats },
    } = await instance().get<{ stats: SpaceUsageStats }>(
      `/groups/${groupId}/parking/spaces/${spaceId}/usage-stats`
    );

    return stats;
  });

export const useSpaceActivityStatsQuery = (
  groupId: number,
  spaceId: string,
  filters?: {
    days: string | null;
  }
) =>
  useQuery(
    [ParkingQueryKeys.SpaceActivity, { groupId, spaceId, filters }],
    async () => {
      const { data } = await instance().get<SpaceActivityStats[]>(
        `/groups/${groupId}/parking/spaces/${spaceId}/activity-stats`,
        {
          params: filters,
        }
      );

      return data;
    }
  );

export const useClearSessionMutation = createAxiosMutation(
  async ({ groupId, spaceId }: { groupId: Id; spaceId: Id }) => {
    const { data } = await instance().post(
      `/groups/${groupId}/parking/spaces/${spaceId}/clear-sessions`
    );
    return data;
  },
  {
    successToast: () => ({
      message: "Successfully removed all sessions from the space.",
    }),
    errorToast: (err) => {
      return {
        message: `Unable to clear the session for this parking space. ${getErrorMessage(
          err
        )}`,
      };
    },
    onSettled: (queryClient) => {
      invalidateQueries(queryClient, [
        ParkingQueryKeys.SpaceUsage,
        ParkingQueryKeys.GuestSessions,
      ]);
    },
  }
);
export const useCreateSpaceMutation = createAxiosMutation(
  async ({ groupId, space }: { groupId: number; space: Partial<Space> }) => {
    const data = await instance().post<Space>(
      `/groups/${groupId}/parking/spaces`,
      space
    );
    return data;
  },
  {
    successToast: () => ({
      message: "Successfully created space.",
    }),
    errorToast: (err) => ({
      message: `Error creating space. ${getErrorMessage(err)}`,
    }),
    onSettled: (queryClient) => {
      invalidateSpaceQueries(queryClient);
    },
  }
);

export const useUpdateSpaceMutation = createAxiosMutation(
  async ({
    groupId,
    spaceId,
    space,
  }: {
    groupId: number;
    spaceId: string;
    space: Partial<Space>;
  }) => {
    const { data } = await instance().patch<Space>(
      `/groups/${groupId}/parking/spaces/${spaceId}`,
      space
    );
    return data;
  },
  {
    successToast: () => ({
      message: "Successfully updated space.",
    }),
    errorToast: (err) => ({
      message: `Error updating space. ${getErrorMessage(err)}`,
    }),
    onSuccess: (queryClient) => {
      invalidateSpaceQueries(queryClient);
    },
  }
);

export const useDeleteSpaceMutation = createAxiosMutation(
  async ({ groupId, spaceId }: { groupId: number; spaceId: string }) => {
    const response = await instance().delete(
      `/groups/${groupId}/parking/spaces/${spaceId}`
    );

    return response;
  },
  {
    successToast: () => ({
      message: "Successfully deleted space.",
    }),
    errorToast: (err) => ({
      message: `Error deleting space. ${getErrorMessage(err)}`,
    }),
    onSettled: (queryClient) => {
      invalidateSpaceQueries(queryClient);
    },
  }
);

export const useSpaceSelectQuery = ({
  groupId,
  filterParams = {},
  initialValue = "",
}: {
  groupId: number;
  filterParams?: SpaceFilterOptions;
  initialValue?: string;
}) =>
  useSelectQuery<
    PaginatedResponse<Space>,
    Space,
    [any, any, Record<string, any>]
  >(
    (inputValue) => [
      ParkingQueryKeys.Spaces,
      { groupId },
      { inputValue, ...filterParams },
    ],
    ({ queryKey, pageParam = 1 }) => {
      const [, , params] = queryKey;

      return listSpaces(
        ParkingQueryKeys.Spaces,
        { groupId: Number(groupId) },
        {
          ...params,
          page: pageParam,
          limit: 9999,
          space_number: params.inputValue,
        }
      );
    },
    {
      enabled: !!groupId,
    },
    {
      initialValue,
    }
  );

const invalidateSpaceQueries = async (queryClient: QueryClient) =>
  invalidateQueries(queryClient, [
    ParkingQueryKeys.SpaceUsage,
    ParkingQueryKeys.Spaces,
    ParkingQueryKeys.Space,
    ParkingQueryKeys.ParkingGroup,
  ]);
