import { QueryClient, useQuery } from "@tanstack/react-query";
import { PaginationParams } from "@smartrent/ui";
import { PaginatedResponse, useReducedInfiniteQuery } from "@smartrent/hooks";

import { instance } from "@/react/hooks/api";
import {
  createAxiosMutation,
  invalidateQueries,
} from "@/react/hooks/react-query";
import { getErrorMessage } from "@/react/lib/axios-helpers";
import { ValidationResult, Decal } from "@/react/types";

import { ParkingQueryKeys } from "./parking-query-keys";

interface DecalsFilters extends PaginationParams {
  sort?: "name";
  vehicle_id?: string;
  resident_id?: number;
  active?: boolean;
}

export const useDecalsInfiniteQuery = (
  groupId: number,
  filters: DecalsFilters
) => {
  return useReducedInfiniteQuery(
    [
      ParkingQueryKeys.Decals,
      {
        ...filters,
      },
    ],

    async ({ pageParam }) => {
      const response = await instance().get<PaginatedResponse<Decal>>(
        `/groups/${groupId}/parking/decals`,
        {
          params: { ...filters, page: pageParam },
        }
      );
      return response.data;
    }
  );
};

export const useDecalQuery = (groupId: number, decalNumber: string) =>
  useQuery(
    [ParkingQueryKeys.Decal, { groupId, decalNumber }],
    async () => {
      const response = await instance().get<{ result: ValidationResult }>(
        `/groups/${groupId}/parking/decals/${decalNumber}`
      );

      return response.data.result;
    },
    {
      enabled: !!decalNumber,
    }
  );

export const useAssignVehicleDecalMutation = createAxiosMutation(
  async ({
    groupId,
    decalNumber,
    vehicleId,
  }: {
    groupId: number;
    decalNumber: string;
    vehicleId: string;
  }) => {
    const response = await instance().post(
      `/groups/${groupId}/parking/decals/${decalNumber}/vehicles/${vehicleId}/assign`
    );

    return response;
  },
  {
    successToast: () => ({
      message: "Successfully assigned decal to vehicle.",
    }),
    errorToast: (err) => ({
      message: `Error assigning decal to vehicle. ${getErrorMessage(err)}`,
    }),
    onSettled: async (queryClient) => {
      await invalidateDecalQueries(queryClient);
    },
  }
);

export const useAssignPropertyToDecalMutation = createAxiosMutation(
  async ({ groupId, decalCode }: { groupId: number; decalCode: string }) => {
    const response = await instance().post(
      `/groups/${groupId}/parking/decals/${decalCode}`
    );

    return response;
  },
  {
    successToast: () => ({
      message: "Successfully assigned decal to property.",
    }),
    errorToast: (err) => ({
      message: `Error assigning decal to property. ${getErrorMessage(err)}`,
    }),
    onSettled: async (queryClient) => {
      await invalidateDecalQueries(queryClient);
    },
  }
);

export const useUnassignDecalMutation = createAxiosMutation(
  async ({ groupId, decal }: { groupId: number; decal: Decal }) => {
    const response = await instance().delete(
      `/groups/${groupId}/parking/decals/${decal.code}`
    );

    return response;
  },
  {
    successToast: () => ({
      message: "Successfully unassigned decal.",
    }),
    errorToast: (err) => ({
      message: `Error unassigning decal. ${getErrorMessage(err)}`,
    }),
    onSettled: async (queryClient) => {
      await invalidateDecalQueries(queryClient);
    },
  }
);

const invalidateDecalQueries = async (queryClient: QueryClient) =>
  invalidateQueries(queryClient, [
    ParkingQueryKeys.Decal,
    ParkingQueryKeys.Decals,
    ParkingQueryKeys.Reservations,
    ParkingQueryKeys.Spaces,
    ParkingQueryKeys.Vehicle,
    ParkingQueryKeys.Vehicles,
  ]);
