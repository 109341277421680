import { useCallback } from "react";
import { QueryKey, useQueryClient } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";

import { ListQueryResponse, PaginationParams, useToast } from "@smartrent/ui";
import { useMutationCompat, useQueryCompat } from "@smartrent/hooks";

import { Region } from "@/react/types";
import { instance } from "@/react/hooks/api";
import { getErrorMessage } from "@/react/lib/axios-helpers";
import {
  AxiosMutationConfig,
  createAxiosQuery,
} from "@/react/hooks/react-query";

export interface GetRegionsParams extends PaginationParams {
  name?: string;
  ids?: string;
  exclude?: string;
  enabled?: boolean;
  has_groups?: boolean;
}

export const useRegionsQuery = (params: GetRegionsParams = {}) =>
  useQueryCompat<
    ["regions", GetRegionsParams],
    ListQueryResponse<Region>,
    AxiosError<unknown>
  >(
    ["regions", params],
    async () =>
      (
        await instance().get<ListQueryResponse<Region>>("/groups/regions", {
          params,
        })
      ).data,
    { enabled: params.enabled !== false }
  );

export const useRegionAxiosQuery = createAxiosQuery(
  "regions",
  async ({ regionId }: { regionId?: number | string }) =>
    (await instance().get<Region>(`/groups/regions/${regionId}`)).data
);

export const useHasRegionsQuery = () =>
  useQueryCompat<["regions", "has_regions"], boolean, AxiosError<unknown>>(
    ["regions", "has_regions"],
    async () =>
      ((
        await instance().get<ListQueryResponse<Region>>("/groups/regions", {
          params: { limit: 1 },
        })
      ).data?.total_records ?? 0) > 0
  );

// This is used for passing into the paginated selector, which does not use react query.
export const fetchRegions = async (params: GetRegionsParams = {}) =>
  (
    await instance().get<ListQueryResponse<Region>>("/groups/regions", {
      params,
    })
  ).data;

export async function fetchRegionsForTable({
  queryKey,
}: {
  queryKey: QueryKey;
}) {
  const [, , filters] = queryKey as [string, string, PaginationParams];
  const { data } = await instance().get<ListQueryResponse<Region>>(
    "/groups/regions",
    {
      params: filters,
    }
  );

  return data as ListQueryResponse<Region>;
}

export const useInvalidateRegionsQueries = () => {
  const queryClient = useQueryClient();

  return useCallback(
    (params?: GetRegionsParams | Region["id"]) =>
      queryClient.invalidateQueries(params ? ["regions", params] : ["regions"]),
    [queryClient]
  );
};

export const useRemoveRegionsQueries = () => {
  const queryClient = useQueryClient();

  return useCallback(
    (params?: GetRegionsParams | Region["id"]) =>
      queryClient.removeQueries(params ? ["regions", params] : ["regions"]),
    [queryClient]
  );
};

interface AddRegionMutationOptions {
  region: Pick<Region, "name">;
}

export const useAddRegionMutation = (
  options?: AxiosMutationConfig<AddRegionMutationOptions, Region>
) => {
  const setToast = useToast();
  const invalidateRegionsQueries = useInvalidateRegionsQueries();

  return useMutationCompat(({ region }: AddRegionMutationOptions) => {
    return handleQueryResult<Region>({
      query: instance().post("/groups/regions", region),
      invalidateQueries: invalidateRegionsQueries,
    })
      .then((response) => {
        setToast({
          status: "success",
          message: "Successfully added region.",
        });
        return response;
      })
      .catch((err) => {
        setToast({
          status: "error",
          message: getErrorMessage(err),
        });
        throw err;
      });
  }, options);
};

interface UpdateRegionMutationOptions {
  regionId: Region["id"];
  region: Partial<Region>;
}

export const useUpdateRegionMutation = (
  options?: AxiosMutationConfig<UpdateRegionMutationOptions, Region>
) => {
  const setToast = useToast();
  const invalidateRegionsQueries = useInvalidateRegionsQueries();

  return useMutationCompat(
    ({ regionId, region }: UpdateRegionMutationOptions) => {
      return handleQueryResult<Region>({
        query: instance().patch(`/groups/regions/${regionId}`, region),
        invalidateQueries: invalidateRegionsQueries,
      })
        .then((response) => {
          setToast({
            status: "success",
            message: "Successfully updated region.",
          });
          return response;
        })
        .catch((err) => {
          setToast({
            status: "error",
            message: getErrorMessage(err),
          });
          throw err;
        });
    },
    options
  );
};

interface DeleteRegionMutationOptions {
  regionId: Region["id"];
}

export const useDeleteRegionMutation = (
  options?: AxiosMutationConfig<DeleteRegionMutationOptions>
) => {
  const setToast = useToast();
  const invalidateRegionsQueries = useInvalidateRegionsQueries();

  return useMutationCompat<
    any,
    DeleteRegionMutationOptions,
    AxiosError<unknown>
  >(async ({ regionId }: DeleteRegionMutationOptions) => {
    return handleQueryResult({
      query: instance().delete<null>(`/groups/regions/${regionId}`),
      invalidateQueries: invalidateRegionsQueries,
    })
      .then(() => {
        setToast({
          status: "success",
          message: "Successfully deleted region.",
        });
        return null;
      })
      .catch((err) => {
        setToast({
          status: "error",
          message: getErrorMessage(err),
        });
        throw err;
      });
  }, options);
};

interface HandleQueryResultOptions<R> {
  query: Promise<AxiosResponse<R>>;
  invalidateQueries: () => any;
}

async function handleQueryResult<R>({
  query,
  invalidateQueries,
}: HandleQueryResultOptions<R>): Promise<AxiosResponse<R>> {
  return query.then((response) => {
    invalidateQueries();
    return response;
  });
}
