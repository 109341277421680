import { pick } from "lodash-es";
import { AxiosError } from "axios";

import { instance } from "@/react/hooks/api";
import {
  createAxiosQuery,
  createAxiosMutation,
  CreateMutationOptions,
} from "@/react/hooks/react-query";
import { getErrorMessage } from "@/react/lib/axios-helpers";
import { TourConfiguration } from "@/react/types";

interface GroupTourSettingsResponse<Config = TourConfiguration> {
  tours_base_url: string;
  tour_config: Config & Pick<TourConfiguration, "group_id">;
}

type GroupTourSettingsPayload<Config = TourConfiguration> = Omit<Config, "id">;

const mutationOptions: Pick<
  CreateMutationOptions,
  "successToast" | "errorToast"
> = {
  successToast: () => ({
    message: "Tour settings updated successfully.",
  }),
  errorToast: (err) => ({
    message: `Error updating the tour settings. ${getErrorMessage(err)}`,
  }),
};

export const useGroupTourConfigQuery = createAxiosQuery(
  "group-tour-config",
  async (groupId: number | string) => {
    const { data } = await instance().get<GroupTourSettingsResponse>(
      `/groups/${groupId}/settings/tours`
    );

    return data;
  }
);

export const useUpdateGroupTourConfigMutation = createAxiosMutation(
  async ({ group_id, ...payload }: GroupTourSettingsPayload) => {
    const { data } = await instance().patch<GroupTourSettingsResponse>(
      `/groups/${group_id}/settings/tours`,
      payload
    );

    return data;
  },
  {
    ...mutationOptions,
    onSettled: async (queryClient, _result, _err, { group_id }) => {
      await Promise.all([
        queryClient.invalidateQueries(["group-tour-config", group_id]),
        queryClient.invalidateQueries([
          "group-tour-config-marketing-theme",
          group_id,
        ]),
        queryClient.invalidateQueries([
          "group-tour-config-marketing-button",
          group_id,
        ]),
      ]);
    },
    onSuccess: (queryClient, result, { group_id }) => {
      queryClient.setQueryData(["group-tour-config", group_id], result);
    },
  }
);

type MarketingThemeSettings = Pick<
  TourConfiguration,
  | "group_id"
  | "property_name"
  | "primary_color"
  | "phone_number"
  | "property_image_url"
  | "property_logo_url"
  | "welcome_message"
  | "always_show_welcome_message"
  | "end_tour_message"
>;
type GroupTourMarketingThemeSettingsResponse =
  GroupTourSettingsResponse<MarketingThemeSettings>;
type GroupTourMarketingThemeSettingsPayload =
  GroupTourSettingsPayload<MarketingThemeSettings>;

function extractMarketingThemeSettings(
  config: Omit<TourConfiguration, "id">
): MarketingThemeSettings {
  return pick(config, [
    "group_id",
    "property_name",
    "primary_color",
    "phone_number",
    "property_image_url",
    "property_logo_url",
    "welcome_message",
    "always_show_welcome_message",
    "end_tour_message",
  ]);
}

export const useGroupTourMarketingThemeSettingsQuery = createAxiosQuery(
  "group-tour-config-marketing-theme",
  async (groupId: number | string) => {
    const { data } = await instance().get<GroupTourSettingsResponse>(
      `/groups/${groupId}/settings/tours`
    );
    const {
      tour_config: { id, ...tour_config },
    } = data;

    return {
      ...data,
      tour_config: {
        id,
        ...extractMarketingThemeSettings(tour_config),
      },
    } as GroupTourMarketingThemeSettingsResponse;
  }
);

export const useUpdateGroupTourMarketingThemeSettingsMutation =
  createAxiosMutation<
    GroupTourMarketingThemeSettingsResponse,
    AxiosError<any>,
    GroupTourMarketingThemeSettingsPayload
  >(
    async ({
      group_id,
      ...payload
    }: GroupTourMarketingThemeSettingsPayload) => {
      const { data } = await instance().patch<GroupTourSettingsResponse>(
        `/groups/${group_id}/settings/tours`,
        payload
      );

      return {
        ...data,
        tour_config: extractMarketingThemeSettings(data.tour_config),
      } as GroupTourMarketingThemeSettingsResponse;
    },
    {
      ...mutationOptions,
      onSettled: async (queryClient, _result, _err, { group_id }) => {
        await Promise.all([
          queryClient.invalidateQueries(["group-tour-config", group_id]),
          queryClient.invalidateQueries([
            "group-tour-config-marketing-theme",
            group_id,
          ]),
        ]);
      },
      onSuccess: (queryClient, result, { group_id }) => {
        queryClient.setQueryData(
          ["group-tour-config-marketing-theme", group_id],
          result
        );
      },
    }
  );

type MarketingButtonSettings = Pick<
  TourConfiguration,
  | "group_id"
  | "button_text"
  | "button_text_color"
  | "button_background_color"
  | "button_font_size"
  | "button_border_radius"
>;
type GroupTourMarketingButtonSettingsResponse =
  GroupTourSettingsResponse<MarketingButtonSettings>;
type GroupTourMarketingButtonSettingsPayload =
  GroupTourSettingsPayload<MarketingButtonSettings>;

function extractMarketingButtonSettings(
  config: Omit<TourConfiguration, "id">
): MarketingButtonSettings {
  return pick(config, [
    "group_id",
    "button_text",
    "button_text_color",
    "button_background_color",
    "button_font_size",
    "button_border_radius",
  ]);
}

export const useGroupTourMarketingButtonSettingsQuery = createAxiosQuery(
  "group-tour-config-marketing-button",
  async (groupId: number | string) => {
    const { data } = await instance().get<GroupTourSettingsResponse>(
      `/groups/${groupId}/settings/tours`
    );
    const {
      tour_config: { id, ...tour_config },
    } = data;

    return {
      ...data,
      tour_config: {
        id,
        ...extractMarketingButtonSettings(tour_config),
      },
    } as GroupTourMarketingButtonSettingsResponse;
  }
);

export const useUpdateGroupTourMarketingButtonSettingsMutation =
  createAxiosMutation(
    async ({
      group_id,
      ...payload
    }: GroupTourMarketingButtonSettingsPayload) => {
      const { data } = await instance().patch<GroupTourSettingsResponse>(
        `/groups/${group_id}/settings/tours`,
        payload
      );

      return {
        ...data,
        tour_config: extractMarketingButtonSettings(data.tour_config),
      } as GroupTourMarketingButtonSettingsResponse;
    },
    {
      ...mutationOptions,
      onSettled: async (queryClient, _result, _err, { group_id }) => {
        await Promise.all([
          queryClient.invalidateQueries(["group-tour-config", group_id]),
          queryClient.invalidateQueries([
            "group-tour-config-marketing-button",
            group_id,
          ]),
        ]);
      },
      onSuccess: (queryClient, result, { group_id }) => {
        queryClient.setQueryData(
          ["group-tour-config-marketing-button", group_id],
          result
        );
      },
    }
  );
