import { mapValues, pick } from "lodash-es";

import { Option } from "@/react/hooks/useOptions";

type Transformer = (value: any) => Option[];

interface RealPageOption {
  label: string;
  value: string;
  text: string;
}

const transformOption = (values: RealPageOption[]): Option[] =>
  values.map((value) => pick(value, ["label", "value"])) ?? [];

const transformers: { [key: string]: Transformer } = {
  activity_types: transformOption,
  contact_types: transformOption,
  marketing_sources: transformOption,
};

export const transformRealPageOptions = (
  configurationOptions?: { [key: string]: any }[]
) =>
  mapValues(transformers, (transformValue: Transformer, key) => {
    const values = (configurationOptions as any)?.[key] ?? [];

    return transformValue(values);
  });
