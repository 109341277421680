import { useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";
import { StyleSheet } from "react-native";

import { Link, useTheme } from "@smartrent/ui";
import { useWindowDimensionsQuery } from "@smartrent/hooks";
import { ArrowLeft } from "@smartrent/icons";

import { useSearchBox } from "@/react/hooks/useSearchBox";
import { useGetUnitQuery } from "@/react/queries/mgmt-api/units/units";
import { useGroup } from "@/react/queries/mgmt-api/group";
import { useCurrentUser } from "@/react/context/mgmt-api/user";

import * as links from "@/lib/links";

import {
  GroupHeader,
  Sidebar,
  unitNavItems,
} from "@/react/common/page_elements";

export const UnitSidebar = () => {
  const { colors } = useTheme();
  const { isBelowMedium } = useWindowDimensionsQuery({
    isBelowMedium: ({ width }) => width <= 768,
  });
  const location = useLocation();

  const params: Record<string, any> = useParams();
  const { data: unit } = useGetUnitQuery(params?.unitId);

  const groupId = params?.groupId || unit?.group_id;

  const { data: group } = useGroup(groupId);

  const header = useMemo(
    () => <GroupHeader group={group} unit={unit} subtextColor="light" />,
    [group, unit]
  );

  const user = useCurrentUser();

  const navItems = useMemo(() => {
    return unitNavItems(unit, location.pathname, user);
  }, [unit, location.pathname, user]);

  useSearchBox(groupId);

  return (
    <Sidebar
      color={isBelowMedium ? null : "primary"}
      header={header}
      navItems={navItems}
      backButton={
        isBelowMedium || !groupId ? null : (
          <Link
            to={links.group.units.list({ groupId })}
            style={[styles.backButton, { backgroundColor: colors.secondary }]}
          >
            <ArrowLeft />
            All Units
          </Link>
        )
      }
    />
  );
};

const styles = StyleSheet.create({
  backButton: {
    display: "flex",
    justifyContent: "space-between",
    paddingVertical: 12,
    paddingHorizontal: 16,
    color: "white",
    borderRadius: 3,
    cursor: "pointer",
    fontWeight: "bold",
    fontSize: 21,
  },
});
