import { QueryClient, useQuery } from "@tanstack/react-query";
import { ListQueryResponse, PaginationParams } from "@smartrent/ui";

import { instance } from "@/react/hooks/api";
import { Assignable, AssignableType } from "@/react/types";
import {
  createAxiosMutation,
  invalidateQueries,
} from "@/react/hooks/react-query";
import { getErrorMessage } from "@/react/lib/axios-helpers";

import { ParkingQueryKeys } from "./parking-query-keys";

interface ReservationMutationOptions {
  groupId: number;
  residentId: number;
  assignableType: AssignableType;
  assignableId: string;
  provider_configuration?: any;
}

interface ListResidentReservationsFilters extends PaginationParams {
  sort?: "space_number";
}

export const useResidentReservationsQuery = (
  groupId: number,
  residentId: number,
  filters?: ListResidentReservationsFilters
) => {
  return useQuery(
    ["reservations", { residentId, groupId }, filters],
    async () => {
      const response = await instance().get<ListQueryResponse<Assignable>>(
        `/groups/${groupId}/parking/assignables`,
        { params: { ...filters, resident_id: residentId } }
      );

      return response?.data;
    }
  );
};

export const useRentableItemQuery = (groupId: number) => {
  return useQuery(["space_reservations", { groupId }], async () => {
    const response = await instance().get<any>(
      `/groups/${groupId}/parking/space_reservations`
    );

    return response?.data || [];
  });
};

export const useCreateReservationMutation = createAxiosMutation(
  async ({
    groupId,
    residentId,
    assignableType,
    assignableId,
  }: ReservationMutationOptions) => {
    const isSpace = assignableType === "space";
    const url = isSpace
      ? `/groups/${groupId}/parking/spaces/${assignableId}/residents/${residentId}`
      : `/groups/${groupId}/parking/sections/${assignableId}/residents/${residentId}`;

    const response = await instance().post(url, {});

    return response;
  },
  {
    successToast: (_data, { assignableType, provider_configuration }) => ({
      message: `Successfully assigned parking ${assignableType} to resident. ${
        provider_configuration
          ? `Make sure to update assignment in ${provider_configuration.provider.provider_name}.`
          : ""
      }`.trim(),
    }),
    errorToast: (err, { assignableType }) => ({
      message: `Error assigning ${assignableType}. ${getErrorMessage(err)}`,
    }),
    onSettled: (queryClient) => {
      invalidateReservationsQuery(queryClient);
    },
  }
);

export const useDeleteReservationMutation = createAxiosMutation(
  async ({
    groupId,
    residentId,
    assignableType,
    assignableId,
  }: ReservationMutationOptions) => {
    const isSpace = assignableType === "space";
    const url = isSpace
      ? `/groups/${groupId}/parking/spaces/${assignableId}/residents/${residentId}`
      : `/groups/${groupId}/parking/sections/${assignableId}/residents/${residentId}`;

    const response = await instance().delete(url, {});

    return response;
  },
  {
    successToast: (_data, { assignableType, provider_configuration }) => ({
      message: `Successfully unassigned ${assignableType} from resident. ${
        provider_configuration
          ? `Make sure to update assignment in ${provider_configuration.provider.provider_name}.`
          : ""
      }`.trim(),
    }),
    errorToast: (err, { assignableType }) => ({
      message: `Error unassigning ${assignableType}. ${getErrorMessage(err)}`,
    }),
    onSettled: (queryClient) => {
      invalidateReservationsQuery(queryClient);
    },
  }
);

const invalidateReservationsQuery = async (queryClient: QueryClient) =>
  invalidateQueries(queryClient, [
    ParkingQueryKeys.Reservations,
    ParkingQueryKeys.Assignables,
    ParkingQueryKeys.Space,
    ParkingQueryKeys.ParkingUsers,
    ParkingQueryKeys.Spaces,
  ]);
