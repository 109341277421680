import { instance } from "@/react/hooks/api";
import { createAxiosMutation } from "@/react/hooks/react-query";

export async function logout() {
  await instance().delete("/sessions");
  window.location.href = "/login";
}

export type SignedIdTokenAuthRequest = {
  id_token: string; // signed id_token from Central Station
};

export const useLinkAccountMutation = createAxiosMutation(
  async ({ id_token }: SignedIdTokenAuthRequest) => {
    return instance({ apiNamespace: "root", version: "v1" }).post<any>(
      `/my-applications/link-account/${id_token}`
    );
  }
);
