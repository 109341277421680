import { useMemo } from "react";
import { useLocation } from "react-router";
import {
  PaginationParams,
  ColumnOptions,
  FormikTextInputField,
  GetQueryKeyFn,
  Cell,
  ListQueryResponse,
} from "@smartrent/ui";

import { QueryFunction } from "@tanstack/react-query";

import { filterObject, StringParams } from "@/react/lib/object-helpers";
import { $Group } from "@/react/types";
import { fetchGroups, GetGroupsFilters } from "@/react/queries/mgmt-api/groups";
import { SelectedItems } from "@/react/hooks/infinite-multiselect/utils";
import { parseStrings } from "@/react/lib/string-helpers";

export interface GroupFilters {
  region_ids?: number[];
  region_names?: string[];
  marketing_name?: string;
  city?: string;
  state?: string;
  zip?: string;
  country?: string;
  has_tour_config?: true | null;
}

export type BulkGroupSelection = GroupFilters & SelectedItems<$Group>;

export type GroupFiltersQueryKey = readonly [
  "filtered-groups",
  BulkGroupSelection,
  PaginationParams
];

export function createGetGroupFiltersQueryKey(
  transformFilters?: (filters: GroupFilters) => GroupFilters
): GetQueryKeyFn<GroupFiltersQueryKey> {
  return ({ filters, page, pageSize, sortColumn, sortDirection }) => {
    if (typeof filters.region_names === "string") {
      filters = {
        ...filters,
        region_names: parseStrings(filters.region_names),
      };
    }

    if (transformFilters) {
      filters = transformFilters(filters);
    }

    return [
      "filtered-groups",
      filters as BulkGroupSelection,
      { page, limit: pageSize, sort: sortColumn, dir: sortDirection },
    ];
  };
}

export const fetchFilteredGroups: QueryFunction<
  ListQueryResponse<$Group>
> = async ({ queryKey: [, filters, pagination] }: any) => {
  const params = convertGroupFiltersToQsParams(
    filters,
    pagination
  ) as GetGroupsFilters;

  const result = await fetchGroups(params);

  return { ...result, current_page: Number(pagination.page) || 1 };
};

export function marketingNameColumn(
  options?: Partial<ColumnOptions<$Group>>
): ColumnOptions<$Group> {
  return {
    name: "marketing_name",
    header: "Marketing Name",
    filter: FormikTextInputField as any,
    render: renderMarketingName,
    ...options,
  };
}

function renderMarketingName({ row }: Cell<$Group>) {
  return row.marketing_name ?? "";
}

export type GroupFilterQsParams = StringParams<GroupFilters>;

export function convertGroupFiltersToQsParams(
  filters: GroupFilters,
  pagination?: PaginationParams,
  selection?: SelectedItems<$Group>
): GroupFilterQsParams & { group_ids?: string; exclude?: string } {
  return filterObject(
    {
      group_ids: selection?.selectedItems.map((group) => group.id).join(","),
      exclude: selection?.deselectedItems.map((group) => group.id).join(","),
      region_names: filters.region_names?.join(","),
      marketing_name: filters.marketing_name,
      city: filters.city,
      state: filters.state,
      zip: filters.zip,
      country: filters.country,
      has_tour_config: filters.has_tour_config ? "true" : undefined,
      ...pagination,
    },
    Boolean
  );
}

function parseGroupFilterQsParams(params: GroupFilterQsParams): GroupFilters {
  return {
    region_names: params.region_ids ? parseStrings(params.region_ids) : [],
    marketing_name: params.marketing_name ?? "",
    city: params.city ?? "",
    state: params.state ?? "",
    zip: params.zip ?? "",
    country: params.country ?? "",
    has_tour_config: params.has_tour_config === "true" || null,
  };
}

export function useGroupFilterQsParams(): GroupFilters {
  const { search } = useLocation();

  return useMemo(() => {
    const params: GroupFilterQsParams = {};

    new URLSearchParams(search).forEach((value, key) => {
      params[key as unknown as keyof GroupFilterQsParams] = value;
    });

    return parseGroupFilterQsParams(params);
  }, [search]);
}
