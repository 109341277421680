import { QueryClient } from "@tanstack/react-query";

import { instance } from "@/react/hooks/api";
import {
  createAxiosMutation,
  invalidateQueries,
} from "@/react/hooks/react-query";
import { getErrorMessage } from "@/react/lib/axios-helpers";
import { Violation } from "@/react/types";

import { ParkingQueryKeys } from "./parking-query-keys";

export const useDismissViolationMutation = createAxiosMutation(
  async ({ groupId, violation }: { groupId: number; violation: Violation }) => {
    const response = await instance().delete(
      `/groups/${groupId}/parking/violations/${violation.id}`
    );
    return response;
  },
  {
    successToast: () => ({
      message: "Successfully dismissed violation.",
    }),
    errorToast: (err) => ({
      message: `Error dismissing violation. ${getErrorMessage(err)}`,
    }),
    onSettled: (queryClient) => {
      invalidateViolationQueries(queryClient);
    },
  }
);

const invalidateViolationQueries = async (queryClient: QueryClient) =>
  invalidateQueries(queryClient, [
    ParkingQueryKeys.Space,
    ParkingQueryKeys.Spaces,
  ]);
