import React from "react";
import { useHistory } from "react-router-dom";

import { useToast } from "@smartrent/ui";

import { PermissionType, can } from "@/react/hooks/permissions";
import { useCurrentUser } from "@/react/context/mgmt-api/user";

import { PermissionKey } from "@/react/common/utils/permissions";

import AccessDenied from "@/react/common/components/AccessDenied";

import FullScreenLoader from "@/react/common/FullScreenLoader";

export interface CheckPermissionWithRedirectProps {
  permission?: PermissionKey | PermissionKey[];
  permissionType?: PermissionType;
  redirectUrl?: string;
}

/**
 * Check if the user has a certain permission.
 *
 * If you specify a redirectUrl, the page will redirect away if the user doesn't have permission.
 * If you don't specify a redirectUrl, the user will see an Access Denied screen
 */
export const CheckPermissionWithRedirect: React.FC<
  { children: React.ReactNode } & CheckPermissionWithRedirectProps
> = ({ permission, permissionType, redirectUrl, children }) => {
  const history = useHistory();
  const setToast = useToast();
  const user = useCurrentUser();

  const requiresPermission = !!permission;
  const requiresPermissionAndMissingPermission =
    requiresPermission && !can(user, permission, permissionType);

  if (requiresPermissionAndMissingPermission && !user) {
    return <FullScreenLoader />;
  } else if (requiresPermissionAndMissingPermission) {
    if (redirectUrl) {
      setToast({
        status: "error",
        message: "You don't have permission to view that page",
      });
      history.replace(redirectUrl);
    } else {
      return <AccessDenied />;
    }
  }

  return <>{children}</>;
};
