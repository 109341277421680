import { useState } from "react";
import { StyleSheet } from "react-native";

import {
  ConfirmDialog,
  ConfirmDialogProps,
  FloatingActionButton,
  useTheme,
} from "@smartrent/ui";

import { TrashX } from "@smartrent/icons";

import { usePermission } from "@/react/hooks/permissions";

import { PermissionKey } from "@/react/common/utils/permissions";

type Action = {
  permission: PermissionKey;
  hasPermission?: boolean;
};

export interface DeleteButtonProps {
  action: Action;
  onPress: () => void;
  askConfirmation?: boolean;
  confirmationProps?: Partial<ConfirmDialogProps>;
}

const defaultConfirmationProps: Pick<
  ConfirmDialogProps,
  "title" | "description" | "confirmType" | "confirmText"
> = {
  title: "Confirm Archive",
  description: "Are you sure you want to archive this record?",
  confirmText: "Archive",
  confirmType: "destructive",
};

export const DeleteButton = ({
  action,
  onPress,
  confirmationProps,
  askConfirmation = false,
}: DeleteButtonProps) => {
  const { colors } = useTheme();
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<boolean>(false);
  const permissionHookResult = usePermission(action.permission);
  const hasPermission = action.hasPermission || permissionHookResult;

  return (
    <>
      {showDeleteConfirmation ? (
        <ConfirmDialog
          {...defaultConfirmationProps}
          visible={showDeleteConfirmation}
          onConfirm={onPress}
          onClose={() => setShowDeleteConfirmation(false)}
          {...confirmationProps}
        />
      ) : null}
      <FloatingActionButton
        disabled={!hasPermission}
        icon={TrashX}
        accessibilityRole="button"
        onPress={
          askConfirmation ? () => setShowDeleteConfirmation(true) : onPress
        }
        style={[
          styles.fab,
          hasPermission ? { backgroundColor: colors.error } : null,
        ]}
      />
    </>
  );
};

const styles = StyleSheet.create({
  fab: {
    position: "relative",
    top: 40,
  },
});
