import { mapValues } from "lodash-es";

import { Option } from "@/react/hooks/useOptions";

type Transformer = (value: any) => Option[];

const transformStrings = (values?: string[]): Option[] =>
  values?.map((value) => ({ label: value, value })) ?? [];

const transformers: { [key: string]: Transformer } = {
  agents: transformStrings,
  sources: transformStrings,
  results: transformStrings,
  priorities: transformStrings,
};

export const transformYardiOptions = (
  configurationOptions?: { [key: string]: any }[]
) =>
  mapValues(transformers, (transformValue: Transformer, key) => {
    const values = (configurationOptions as any)?.[key] ?? [];

    return transformValue(values);
  });
