import { last, range } from "lodash-es";

/**
 * Creates an array of numbers (positive and/or negative) progressing from
 * `start` up to and *possibly* including `end`. A step of `-1` is used if a
 * negative `start` is specified without an `end` or `step`. If `end` is not
 * specified, then the first parameter is considered the end and the value for
 * `start` will be `0`.
 *
 * If the sum of the last value of the non-inclusive range and `step` is equal
 * to `end`, it will be included in the range.
 *
 * @param {number} [start = 0] - The start of the range.
 * @param {number} [end] - The end of the range.
 * @param {number} [step] - The value to increment or decrement by.
 * @returns {number[]} - The range of numbers.
 */
export function inclusiveRange(start: number = 0, end?: number, step?: number) {
  const values = range(start, end, step);

  if (end === undefined) {
    return values;
  }

  const tail = (last(values) ?? start) + (step ?? 1);

  if (tail === end) {
    values.push(tail);
  }

  return values;
}

/**
 * Rounds and formats a raw data point value
 *  - Values less than 10 are rounded to up to two decimal places
 *  - Values between 10 and 100 are rounded to up to one decimal place
 *  - Numbers between 100 and 1,000 are rounded to the nearest integer
 *  - Numbers greater than 999 are rounded to the nearest integer and
 *    formatted with separators, e.g. "2,501"
 *  - Numbers greater than 99,999 are rounded to the nearest thousand, e.g.
 *    "352K"
 */
export function formatDataPoint(value: string | number): string {
  const number = (typeof value === "string" ? parseFloat(value) : value) || 0;
  const magnitude = Math.floor(Math.log10(number) + 1);

  if (magnitude >= 6) {
    return `${Math.round(number / 100).toLocaleString()}K`;
  }

  return number.toLocaleString([], {
    maximumSignificantDigits: Math.max(magnitude, 3),
  });
}

/**
 * Transforms a time value from SQL format (e.g. "18:30:00.000000") to a human-
 * readable format (e.g. "6:30 PM")
 */
export function formatTime(value: string): string {
  const [hour, minute] = value.split(":", 3).map(Number);
  const date = new Date(2000, 0, 1, hour, minute);

  return date.toLocaleTimeString([], {
    hour: "numeric",
    minute: "2-digit",
  });
}
