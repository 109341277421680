import { instance } from "@/react/hooks/api";
import { createAxiosQuery } from "@/react/hooks/react-query";

interface Floors {
  id: string;
  label: string;
  name: string;
  created_at: string;
  updated_at: string;
}

export const useParkingMapFloorsQuery = createAxiosQuery(
  "floors",
  async ({ mapId, groupId }: { mapId: string; groupId: string }) => {
    const { data } = await instance().get<Floors[]>(
      `/groups/${groupId}/parking/maps/${mapId}/floors`
    );

    return data;
  }
);
