import { QueryClient, useQuery } from "@tanstack/react-query";

import { PaginatedResponse, useReducedInfiniteQuery } from "@smartrent/hooks";

import {
  createAxiosMutation,
  invalidateQueries,
} from "@/react/hooks/react-query";

import { getErrorMessage } from "@/react/lib/axios-helpers";

import { Sensor } from "@/react/types";
import { instance } from "@/react/hooks/api";

import { ParkingQueryKeys } from "./parking-query-keys";

export interface SensorFilterOptions {
  space_id?: string;
  active?: boolean;
  dev_eui?: string;
  assigned?: boolean;
  page?: number;
  limit?: number;
  sort?: "name";
  dir?: "asc" | "desc";
}

interface AssignSensorOptions {
  groupId: number;
  sensorId: string;
  spaceId: string;
}

export interface UnassignSensorOptions {
  groupId: number;
  sensorId: string;
  spaceId: string;
}

export const useSensorsInfiniteQuery = (
  groupId: number,
  filters: SensorFilterOptions = {}
) =>
  useReducedInfiniteQuery(
    [ParkingQueryKeys.Sensors, { groupId, ...filters }],
    async ({ pageParam }) => {
      const response = await instance().get<PaginatedResponse<Sensor>>(
        `/groups/${groupId}/parking/sensors`,
        {
          params: { ...filters, page: pageParam },
        }
      );
      return response.data;
    }
  );
export const useSensorQuery = (groupId: number, sensorId: string) =>
  useQuery(
    [ParkingQueryKeys.Sensor, { groupId, sensorId }],
    async () => {
      const {
        data: { sensor },
      } = await instance().get<{ sensor: Sensor }>(
        `/groups/${groupId}/parking/sensors/${sensorId}`
      );

      return sensor;
    },
    {
      enabled: !!sensorId,
    }
  );

export const useAssignSensorMutation = createAxiosMutation(
  async ({ groupId, sensorId, spaceId }: AssignSensorOptions) => {
    const { data } = await instance().post(
      `/groups/${groupId}/parking/sensors/${sensorId}/spaces/${spaceId}`
    );

    return data;
  },
  {
    successToast: () => ({
      message: "Successfully assigned sensor to space.",
    }),
    errorToast: (err) => ({
      message: `Error assigning sensor to space. ${getErrorMessage(err)}`,
    }),
    onSettled: (queryClient) => {
      invalidateSensorQueries(queryClient);
    },
  }
);

export const useUnassignSensorMutation = createAxiosMutation(
  async ({ groupId, sensorId, spaceId }: UnassignSensorOptions) => {
    const { data } = await instance().delete(
      `/groups/${groupId}/parking/sensors/${sensorId}/spaces/${spaceId}`
    );

    return data;
  },
  {
    successToast: () => ({
      message: "Successfully removed sensor from space.",
    }),
    errorToast: (err) => ({
      message: `Error removing sensor from space. ${getErrorMessage(err)}`,
    }),
    onSettled: async (queryClient) => {
      await invalidateSensorQueries(queryClient);
    },
  }
);

const invalidateSensorQueries = async (queryClient: QueryClient) =>
  invalidateQueries(queryClient, [
    ParkingQueryKeys.Sensor,
    ParkingQueryKeys.Space,
    ParkingQueryKeys.Spaces,
    ParkingQueryKeys.Sensors,
  ]);
