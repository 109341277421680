import { useCallback } from "react";
import { QueryClient, useQuery, useQueryClient } from "@tanstack/react-query";

import { PaginatedResponse, useReducedInfiniteQuery } from "@smartrent/hooks";

import { instance } from "@/react/hooks/api";
import { Vehicle } from "@/react/types";
import {
  createAxiosMutation,
  invalidateQueries,
} from "@/react/hooks/react-query";
import { getErrorMessage } from "@/react/lib/axios-helpers";

import { ParkingQueryKeys } from "./parking-query-keys";

export interface VehicleListFilters {
  page?: number;
  resident_id?: number;
  license_plate?: string;
  state?: string | null;
  limit?: number;
  sort?: "name";
  dir?: "asc" | "desc";
}

export const useVehicleInfiniteQuery = (
  groupId: number,
  filters: Omit<VehicleListFilters, "page">
) => {
  return useReducedInfiniteQuery(
    [
      ParkingQueryKeys.Vehicles,
      {
        groupId: groupId,
        ...filters,
      },
    ],

    async ({ pageParam }) => {
      const { data } = await instance().get<PaginatedResponse<Vehicle>>(
        `/groups/${groupId}/parking/vehicles`,
        {
          params: { ...filters, page: pageParam },
        }
      );
      return data;
    }
  );
};

export const useVehiclesPaginatedQuery = (
  groupId: number,
  filters: VehicleListFilters
) => {
  return useQuery(
    [
      ParkingQueryKeys.Vehicles,
      {
        ...filters,
      },
    ],
    async () => {
      const { data } = await instance().get<
        PaginatedResponse<Vehicle> & { current_page: number }
      >(`/groups/${groupId}/parking/vehicles`, {
        params: { ...filters },
      });
      return data;
    }
  );
};

export const useInvalidateResidentVehiclesQuery = () => {
  const queryClient = useQueryClient();
  return useCallback(
    (residentId: number) =>
      queryClient.invalidateQueries([
        ParkingQueryKeys.Vehicles,
        { residentId },
      ]),
    [queryClient]
  );
};

export const useUnitVehiclesQuery = (groupId: number, unitId: number) =>
  useQuery(
    [ParkingQueryKeys.Vehicles, { unitId, groupId }],
    async () => {
      const { data } = await instance().get<{ records: Vehicle[] }>(
        `/groups/${groupId}/parking/units/${unitId}/vehicles`
      );

      return data?.records ?? [];
    },
    {
      enabled: !!unitId,
    }
  );

export const useInvalidateUnitVehiclesQuery = () => {
  const queryClient = useQueryClient();
  return useCallback(
    (unitId: number) =>
      queryClient.invalidateQueries([ParkingQueryKeys.Vehicles, { unitId }]),
    [queryClient]
  );
};

export const useVehicleQuery = (
  vehicleId: string,
  groupId: number,
  residentId: number
) =>
  useQuery(
    [ParkingQueryKeys.Vehicle, { vehicleId, groupId, residentId }],
    async () => {
      const { data } = await instance().get<Vehicle>(
        `/groups/${groupId}/parking/residents/${residentId}/vehicles/${vehicleId}`
      );

      return data;
    }
  );

export const useCreateVehicleMutation = createAxiosMutation(
  async ({
    groupId,
    residentId,
    vehicle,
  }: {
    groupId: number;
    residentId: number;
    vehicle: Partial<Vehicle>;
  }) => {
    const { data } = await instance().post<Vehicle>(
      `/groups/${groupId}/parking/residents/${residentId}/vehicles`,
      vehicle
    );

    return data;
  },
  {
    successToast: () => ({
      message: "Successfully created vehicle.",
    }),
    errorToast: (err) => ({
      message: `Error creating vehicle. ${getErrorMessage(err)}`,
    }),
    onSettled: (queryClient) => {
      invalidateVehicleQueries(queryClient);
    },
  }
);

export const useUpdateVehicleMutation = createAxiosMutation(
  async ({
    vehicle,
    vehicleId,
    groupId,
    residentId,
  }: {
    groupId: number;
    residentId: number;
    vehicleId: string;
    vehicle: Partial<Vehicle>;
  }) => {
    const { data } = await instance().patch<Vehicle>(
      `/groups/${groupId}/parking/residents/${residentId}/vehicles/${vehicleId}`,
      vehicle
    );

    return data;
  },
  {
    successToast: () => ({
      message: "Successfully updated vehicle.",
    }),
    errorToast: (err) => ({
      message: `Error updating vehicle. ${getErrorMessage(err)}`,
    }),
    onSettled: (queryClient) => {
      invalidateVehicleQueries(queryClient);
    },
  }
);

export const useDeleteVehicleMutation = createAxiosMutation(
  async ({
    vehicleId,
    groupId,
    residentId,
  }: {
    groupId: number;
    residentId: number;
    vehicleId: string;
  }) => {
    const response = await instance().delete(
      `/groups/${groupId}/parking/residents/${residentId}/vehicles/${vehicleId}`
    );

    return response;
  },
  {
    successToast: () => ({
      message: "Successfully deleted vehicle.",
    }),
    errorToast: (err) => ({
      message: `Error deleting vehicle. ${getErrorMessage(err)}`,
    }),
    onSettled: (queryClient) => {
      invalidateVehicleQueries(queryClient);
    },
  }
);

export const useAssignVehicleMutation = createAxiosMutation(
  async ({
    groupId,
    residentId,
    vehicleId,
  }: {
    groupId: number;
    residentId: number;
    vehicleId: string;
  }) => {
    const { data } = await instance().post(
      `/groups/${groupId}/parking/residents/${residentId}/vehicles/${vehicleId}/assign`
    );

    return data;
  },
  {
    successToast: () => ({
      message: "Successfully assigned vehicle to resident.",
    }),
    errorToast: (err) => ({
      message: `Error assigning this vehicle to resident. ${getErrorMessage(
        err
      )}`,
    }),
    onSettled: (queryClient) => {
      invalidateVehicleQueries(queryClient);
    },
  }
);

const invalidateVehicleQueries = async (queryClient: QueryClient) =>
  invalidateQueries(queryClient, [
    ParkingQueryKeys.Vehicle,
    ParkingQueryKeys.Vehicles,
    ParkingQueryKeys.Reservations,
    ParkingQueryKeys.Decals,
  ]);
